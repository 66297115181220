#finger-animated-keyboard {
  margin-bottom: 100px;

  // re-style the keyboard
  #keyboard {
    margin-left: 0px;
  }

  .hand-images {
    position: absolute;
    top: 2px;
    left: 0px;
    width: 100%;
    height: 100%;
    // height: 140px;
  }

  img.finger-anim {
    position: absolute;
    top: -2px;
    width: 334px;
    // OLD:
    // top: 0px;
    // width: 316px;

    // clip the images
    clip: rect(0px, 400px, 320px, 0px);
  }

  img.left-hand {
    left: -76px;
    // left: -64px;
  }

  img.right-hand {
    left: 218px;
  }

  .bg-info {
    -webkit-transition: background-color 100ms ease-out;
    -moz-transition: background-color 100ms ease-out;
    -ms-transition: background-color 100ms ease-out;
    -o-transition: background-color 100ms ease-out;
    transition: background-color 100ms ease-out;
  }

  // shadings to light up keyboard
  .bg-1 {
    color: black !important;
    background-color: #f79256;
  }
  .bg-2 {
    color: black !important;
    background-color: #fbd1a2;
  }
  .bg-3 {
    color: black !important;
    background-color: #7dcfb6;
  }
  .bg-4 {
    color: black !important;
    background-color: #8ab2e2;
  }
  .bg-5 {
    color: black !important;
    background-color: #2da8e3;
  }
}
