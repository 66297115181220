blockquote.typist-blockquote {
  display: block;
  padding: calc(15/16 * 1rem) calc(20/16 * 1rem) 0rem calc(45/16 * 1rem);

  position: relative;

  text-align: justify;

  /*Borders - (Optional)*/
  border-left: calc(6/16 * 1rem) solid $typist-blue;
  margin-bottom: 0;

  &::before {
    content: '\201C'; /*Unicode for Left Double Quote*/

    /*Font*/
    // font-family: Georgia, serif;
    font-size: calc(30/16 * 1rem);
    font-weight: $font-weight-bold;
    color: #999;

    /*Positioning*/
    position: absolute;
    left: calc(10/16 * 1rem);
    top: calc(5/16 * 1rem);
  }
}
