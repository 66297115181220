// Place all the styles related to the AdminDashboard controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
#admin-dashboard {
  .small-table {
    td {
      padding-top: 4px;
      padding-bottom: 0px;
      font-size: 14px;
    }
  }
}
