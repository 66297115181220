/* ------------------------------------------------------*/
/* Custom variables */
/* ------------------------------------------------------*/
// Still being used by document production. Delete this once no longer in use
$root-container-width: $container-desktop;

/* ------------------------------------------------------*/
/* Test center styles */
/* ------------------------------------------------------*/

#test-centre {
  // Thin progress bar found in test centre cards
  .test-centre-progress-header {
    .thin-progress {
      height: 0.3125rem;
    }

    // Small animation of the time display sliding in when time is loaded
    .progress-display {
      transition: transform 0.5s;
      transform: translateX(-100%);
      &.time-loaded {
        transform: translateX(0);
      }
    }
  }
}
