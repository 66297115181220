// REMOVE THIS LATER .. just the id
#loading-keyboard {
  margin-top: -20px;
  $key-size: 6px;
  $key-margin-right: 3px;
  $row-spacing: 2px;
  $border-radius: 2px;
  font-size: 0px;

  ul li {
    display: inline;
    padding: $key-size;
    margin: 0px $key-margin-right 0px 0px;
    background: var(--loading-keyboard-key-bg, white);
    border-radius: $border-radius;
    text-decoration: none;
    // -webkit-box-shadow: 1px 1px 1px 0px #8fd1f0;
    // -moz-box-shadow: 1px 1px 1px 0px #8fd1f0;
    // box-shadow: 2px 2px 2px 0px #8fd1f0;
  }

  .middle-row,
  .bottom-row {
    margin-top: $key-size * 2 + $row-spacing;
  }

  ul.top-row,
  ul.middle-row,
  ul.bottom-row {
    // margin-top: 0px;
    padding-left: 0px;
  }
  ul.bottom-row {
    #left-key {
      padding: $key-size calc(($key-size * 3 + $key-margin-right)/2);
    }
    #center-key {
      padding: $key-size calc(($key-size * 8 + $key-margin-right * 3)/2 - 1px);
    }
    #right-key {
      padding: $key-size calc(($key-size * 3 + $key-margin-right)/2);
    }
  }
  .pressed {
    transition: background-color 0.2s ease-out;
    -ms-transition: background-color 0.2s ease-out;
    -moz-transition: background-color 0.2s ease-out;
    -webkit-transition: background-color 0.2s ease-out;

    position: relative;
    background-color: var(--loading-keyboard-key-pressed-bg, #8fd1f0);
    border-color: var(--loading-keyboard-key-pressed-border-color, #2da8e3); 
    top: 1px;
    left: 1px;
    -webkit-box-shadow: 0px 0px 0px 0px var(--loading-keyboard-key-pressed-box-shadow-color, #bce3f6);
    -moz-box-shadow: 0px 0px 0px 0px var(--loading-keyboard-key-pressed-box-shadow-color, #bce3f6);
    box-shadow: 0px 0px 0px 0px var(--loading-keyboard-key-pressed-box-shadow-color, #bce3f6);
  }
}

.loading-keyboard {
  margin-top: -20px;
  $key-size: 6px;
  $key-margin-right: 3px;
  $row-spacing: 2px;
  $border-radius: 2px;
  font-size: 0px;

  ul li {
    display: inline;
    padding: $key-size;
    margin: 0px $key-margin-right 0px 0px;
    background: var(--loading-keyboard-key-bg, white);
    border-radius: $border-radius;
    text-decoration: none;
    // -webkit-box-shadow: 1px 1px 1px 0px #8fd1f0;
    // -moz-box-shadow: 1px 1px 1px 0px #8fd1f0;
    // box-shadow: 2px 2px 2px 0px #8fd1f0;
  }

  .middle-row,
  .bottom-row {
    margin-top: $key-size * 2 + $row-spacing;
  }

  ul.top-row,
  ul.middle-row,
  ul.bottom-row {
    // margin-top: 0px;
    padding-left: 0px;
  }
  ul.bottom-row {
    #left-key {
      padding: $key-size calc(($key-size * 3 + $key-margin-right)/2);
    }
    #center-key {
      padding: $key-size calc((($key-size * 8 + $key-margin-right * 3)/2 - 1px));
    }
    #right-key {
      padding: $key-size calc(($key-size * 3 + $key-margin-right)/2);
    }
  }
  .pressed {
    transition: background-color 0.2s ease-out;
    -ms-transition: background-color 0.2s ease-out;
    -moz-transition: background-color 0.2s ease-out;
    -webkit-transition: background-color 0.2s ease-out;

    position: relative;
    background-color: var(--loading-keyboard-key-pressed-bg, #8fd1f0);
    border-color: var(--loading-keyboard-key-pressed-border-color, #2da8e3); 
    top: 1px;
    left: 1px;
    -webkit-box-shadow: 0px 0px 0px 0px #bce3f6;
    -moz-box-shadow: 0px 0px 0px 0px #bce3f6;
    box-shadow: 0px 0px 0px 0px #bce3f6;
  }
}

#loading-keyboard-nav {
  // nav positioning fix
  line-height: 0px;
  padding: 11px 12px 11px 17px;

  $key-size: 3px;
  $key-margin-right: 2px;
  $row-spacing: 1px;
  $border-radius: 1px;
  font-size: 0px;

  ul li {
    display: inline;
    padding: $key-size;
    margin: 0px $key-margin-right 0px 0px;
    background: var(--loading-keyboard-key-bg, white);
    border-radius: $border-radius;
    text-decoration: none;
    // -webkit-box-shadow: 1px 1px 1px 0px #8fd1f0;
    // -moz-box-shadow: 1px 1px 1px 0px #8fd1f0;
    // box-shadow: 2px 2px 2px 0px #8fd1f0;
  }

  .middle-row,
  .bottom-row {
    margin-top: $key-size * 2 + $row-spacing;
  }

  ul.top-row,
  ul.middle-row,
  ul.bottom-row {
    // margin-top: 0px;
    padding-left: 0px;
  }
  ul.bottom-row {
    #left-key {
      padding: $key-size calc(($key-size * 3 + $key-margin-right)/2);
    }
    #center-key {
      padding: $key-size calc((($key-size * 8 + $key-margin-right * 3)/2 - 1px));
    }
    #right-key {
      padding: $key-size calc(($key-size * 3 + $key-margin-right)/2);
    }
  }
  .pressed {
    transition: background-color 0.2s ease-out;
    -ms-transition: background-color 0.2s ease-out;
    -moz-transition: background-color 0.2s ease-out;
    -webkit-transition: background-color 0.2s ease-out;

    position: relative;
    background-color: #8fd1f0;
    border-color: #2da8e3;
    top: 1px;
    left: 1px;
    -webkit-box-shadow: 0px 0px 0px 0px var(--loading-keyboard-key-pressed-box-shadow-color, #bce3f6);
    -moz-box-shadow: 0px 0px 0px 0px var(--loading-keyboard-key-pressed-box-shadow-color, #bce3f6);
    box-shadow: 0px 0px 0px 0px var(--loading-keyboard-key-pressed-box-shadow-color, #bce3f6);
  }
}

// pulsating background
@keyframes pulse {
  50% {
    background-color: $navbar-default-link-active-bg;
  }
}

.keyboard-nav-pulsate {
  animation: pulse 1.2s ease-in-out infinite;
}
