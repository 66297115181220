// body {
//     @media print {
//         @page { size: A4 landscape; }
//     }
// }

#certificates {
  span {
    font-family: 'Arial', Arial, serif;
  }
  p,
  li {
    font-size: 14px;
  }

  #print-certificate-div {
    // height: 100%;
    // -webkit-transform: rotate(-90deg); -moz-transform:rotate(-90deg);
    // filter:progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    // width: 100vw;
    @media print {
      width: 210mm;
      height: 100%;
      img {
        display: block;
        width: 100%;
      }
    }

    #view-certificate {
      // margin: 0px;
      // padding: 0px;
      // height: 500px;
      // center, match width, maintain aspect ratio of background image
      // background: image-url('Typist-Certificate-R3-1584x1224.png');
      // background-size:     cover;                      /* <------ */
      // background-repeat:   no-repeat;
      img {
        display: block;
        width: 100%;
        height: auto;
      }

      // .content {
      //     position: absolute;
      // }

      // .content {
      // position: absolute;
      span {
        position: absolute;
        color: #57bbeb;
        @media print {
          color: #57bbeb !important;
        }
      }
      // }

      .print-name {
        // font-size: 24px;
        font-size: 14px;
        top: 60%;
        left: 9%;
      }
      .print-date {
        // font-size: 16px;
        font-size: 10px;
        top: 77%;
        left: 9.1%;
      }
      .print-time {
        // font-size: 20px;
        font-size: 12px;
        top: 88%;
        left: 9.1%;
      }
      .print-speed {
        // font-size: 20px;
        font-size: 12px;
        top: 88%;
        left: 26.4%;
      }
      .print-accuracy {
        // font-size: 20px;
        font-size: 12px;
        top: 88%;
        left: 43.8%;
      }
      .print-instructor-name {
        // font-size: 14px;
        font-size: 8px;
        font-weight: $font-weight-bold;

        left: 81%;
        top: 74%;

        width: 148px; // we create a box and center the text within it
        -webkit-transform: translate(-50%, 0);
        color: #444 !important;
      }
      .print-instructor-college {
        // font-size: 10px;
        font-size: 5px;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        // font-weight: $font-weight-bold;

        left: 81%;
        top: 77%;

        width: 141px; // we create a box and center the text within it
        -webkit-transform: translate(-50%, 0);
        color: #666 !important;
      }
    }

    @media print {
      #view-certificate {
        width: 100%; // needed to override whatever the width is specified
        // margin: 0px;
        // padding: 0px;
        // height: 500px;
        // center, match width, maintain aspect ratio of background image
        // background: image-url('Typist-Certificate-R3-1584x1224.png');
        // background-size:     cover;                      /* <------ */
        // background-repeat:   no-repeat;
        img {
          display: block;
          width: 100%;
          height: auto;
        }

        // .content {
        //     position: absolute;
        // }

        // .content {
        // position: absolute;
        span {
          position: absolute;
          color: #57bbeb;
          @media print {
            color: #57bbeb !important;
          }
        }
        // }

        .print-name {
          font-size: 24px;
          top: 368px;
          left: 72px;
        }
        .print-date {
          font-size: 16px;
          top: 474px;
          left: 72px;
        }
        .print-time {
          font-size: 20px;
          top: 540px;
          left: 72px;
        }
        .print-speed {
          font-size: 20px;
          top: 540px;
          left: 209px;
        }
        .print-accuracy {
          font-size: 20px;
          top: 540px;
          left: 347px;
        }
        .print-instructor-name {
          font-size: 14px;
          font-weight: $font-weight-bold;

          left: 642px;
          top: 454px;

          width: 141px; // we create a box and center the text within it
          -webkit-transform: translate(-50%, 0);
          color: #444 !important;
        }
        .print-instructor-college {
          font-size: 10px;
          font-weight: $font-weight-bold;
          text-transform: uppercase;
          // font-weight: $font-weight-bold;

          left: 642px;
          top: 472px;

          width: 141px; // we create a box and center the text within it
          -webkit-transform: translate(-50%, 0);
          color: #666 !important;
        }
      }
    }
  }
}
