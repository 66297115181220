// Styling for the div
#badges-div p {
  color: #999;
}

#badges-div .minus-6-margin {
  margin-top: -8px;
  font-size: 14px;
}

// Styling for the badges
// Variables
$badge-size-lg: 69px;
$badge-title-height: 0px;
$badge-title-margin-top: 0px;

div.badge-box {
  height: ($badge-size-lg + $badge-title-height + $badge-title-margin-top); // Must fit both badge and title
  width: $badge-size-lg;
  margin-bottom: 20px;
}

.badge-box .badge-background {
  height: $badge-size-lg;
  width: $badge-size-lg;
}

.badge-background {
  background-color: #faa919;
  background: -moz-linear-gradient(
    -45deg,
    #fbb430 0,
    #fbb430 26%,
    #faa919 26%,
    #faa919 39%,
    #fbb430 39%,
    #fbb430 52%,
    #faa919 52%,
    #faa919 57%,
    #fbb430 57%,
    #fbb430 78%,
    #faa919 78%,
    #faa919 90%,
    #fbb430 90%,
    #fbb430 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    color-stop(0, #fbb430),
    color-stop(26%, #fbb430),
    color-stop(26%, #faa919),
    color-stop(39%, #faa919),
    color-stop(39%, #fbb430),
    color-stop(52%, #fbb430),
    color-stop(52%, #faa919),
    color-stop(57%, #faa919),
    color-stop(57%, #ffc10d),
    color-stop(78%, #ffc10d),
    color-stop(78%, #faa919),
    color-stop(90%, #faa919),
    color-stop(90%, #ffc10d),
    color-stop(100%, #ffc10d)
  );
  background: -webkit-linear-gradient(
    -45deg,
    #fbb430 0,
    #fbb430 26%,
    #faa919 26%,
    #faa919 39%,
    #fbb430 39%,
    #fbb430 52%,
    #faa919 52%,
    #faa919 57%,
    #fbb430 57%,
    #fbb430 78%,
    #faa919 78%,
    #faa919 90%,
    #fbb430 90%,
    #fbb430 100%
  );
  background: -o-linear-gradient(
    -45deg,
    #fbb430 0,
    #fbb430 26%,
    #faa919 26%,
    #faa919 39%,
    #fbb430 39%,
    #fbb430 52%,
    #faa919 52%,
    #faa919 57%,
    #fbb430 57%,
    #fbb430 78%,
    #faa919 78%,
    #faa919 90%,
    #fbb430 90%,
    #fbb430 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    #fbb430 0,
    #fbb430 26%,
    #faa919 26%,
    #faa919 39%,
    #fbb430 39%,
    #fbb430 52%,
    #faa919 52%,
    #faa919 57%,
    #fbb430 57%,
    #fbb430 78%,
    #faa919 78%,
    #faa919 90%,
    #fbb430 90%,
    #fbb430 100%
  );
  background: linear-gradient(
    135deg,
    #fbb430 0,
    #fbb430 26%,
    #faa919 26%,
    #faa919 39%,
    #fbb430 39%,
    #fbb430 52%,
    #faa919 52%,
    #faa919 57%,
    #fbb430 57%,
    #fbb430 78%,
    #faa919 78%,
    #faa919 90%,
    #fbb430 90%,
    #fbb430 100%
  );
}

.three-badges > :first-child {
  margin-left: 0px;
}

.badge-background {
  border-radius: 200px;
}

.badge-title {
  @extend .text-center;

  margin-top: $badge-title-margin-top;
  font-weight: 400;
  font-size: 16px;
  color: #3c3c3c;
}

// badgeS
.combo-badge {
  $badge-size: ($badge-size-lg - $badge-size-lg * 0.45);

  background-image: url('images/combo-icon.png');
  background-size: $badge-size $badge-size;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
  // padding-top: ($badge-size-lg - 50)/2;
}

// Link styling
.badge-background:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.badge-box a:hover {
  text-decoration: none;
}
.badge-box a:active {
  text-decoration: none;
}

.badge-row-height-fix {
  @extend .row;
}

.badge-footer {
  font-size: 14px;
  // margin-top: 8px;
}

#badges-div .popover {
  width: 240px;
}
