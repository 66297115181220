#championship-results {
  .access-code {
    // position: absolute;
    top: 30px;
    right: 30px;
    color: rgba(120, 120, 120, 1);
    font-weight: 800;
    font-size: 22px;
  }
  .extra-margin-top {
    margin-top: 18px;
  }
  .message {
    margin-top: 28px;
  }
  .fa-check {
    color: gold;
  }

  #status-row {
    #startButtonText {
      margin-top: 12px;
      width: 240px;
      span {
        font-size: 22px;
      }
    }
    span#timer {
      color: #555;
      font-weight: 500;
      font-size: 50px;
      letter-spacing: 3px;
    }
  }
  .accuracy-warning {
    color: red;
  }
  .accuracy-success {
    color: green;
  }

  .placement-column {
    font-weight: $font-weight-bold;
    font-size: 20px;
    padding-bottom: 0px;
    line-height: 24px;
  }
  .trophy-column {
    padding-top: 3px;
    padding-bottom: 0px;
    .fa-trophy {
      font-size: 32px;
    }
  }
}

.move-up {
  margin-top: -30px;
}
