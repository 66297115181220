#homerowtech {


  // Alternative approach to rendering HRT hands and the highlight squares
  // This allows the highlight squares to be part of the hands svg
  // adding the .RH1Active and .RH1Complete classes to the svg container (one .that has .homerowtech-hands-image-container-svg class)
  // will toggle the appropriate highligh square to render as blue or gray, respectively
  #homerowtech-hands-image { 
    position: relative;
    z-index: 2;

    $completeColor: #ddd;

    // By default, all highlight square should be invisible
    .LH1, .LH2, .LH3, .LH4, .LH5, .RH1, .RH2, .RH3, .RH4, .RH5{
      opacity: 0;
    }

    // Active highlight squares become visible
    &.LH1active .LH1 { opacity: 1;}
    &.LH2active .LH2 { opacity: 1;}
    &.LH3active .LH3 { opacity: 1;}
    &.LH4active .LH4 { opacity: 1;}

    // Completed highlight squares are visible and a different color
    &.LH1complete .LH1 {
      opacity: 1; 
      fill: $completeColor; 
    }

    &.LH2complete .LH2 {
      opacity: 1; 
      fill: $completeColor; 
    }
    &.LH3complete .LH3 {
      opacity: 1; 
      fill: $completeColor; 
    }
    &.LH4complete .LH4 {
      opacity: 1; 
      fill: $completeColor; 
    }

    &.RH1active .RH1 { opacity: 1;}
    &.RH2active .RH2 { opacity: 1;}
    &.RH3active .RH3 { opacity: 1;}
    &.RH4active .RH4 { opacity: 1;}

    &.RH1complete .RH1 {
      opacity: 1; 
      fill: $completeColor; 
      stroke: $completeColor;
    }

    &.RH2complete .RH2 {
      opacity: 1; 
      fill: $completeColor; 
      stroke: $completeColor;
    }
    &.RH3complete .RH3 {
      opacity: 1; 
      fill: $completeColor; 
      stroke: $completeColor;
    }
    &.RH4complete .RH4 {
      opacity: 1; 
      fill: $completeColor; 
      stroke: $completeColor;
    }
    

  }

  // instructions
  #homerowtech-instructions-image {
    position: relative;
    width: 400px;
  }

  .progress-default {
    @extend .btn;
    // @extend .btn-default;
    cursor: default;
    transition: background-color 0.4s ease-in;
    -moz-transition: background-color 0.4s ease-in;
    -webkit-transition: background-color 0.4s ease-in;
  }

  .progress-default:hover {
    color: $btn-default-color;
    background-color: $btn-default-bg;
    border-color: $btn-default-border;
  }

  .progress-info {
    @extend .btn;
    @extend .btn-info;
    border-color: $btn-info-color;
    cursor: info;
  }

  .progress-info:hover {
    color: $btn-info-color;
    background-color: $btn-info-bg;
    border-color: $btn-info-border;
  }

  .progress-success {
    @extend .btn;
    @extend .btn-success;
    background-color: #3d7;
    border-color: #3d7;
    // TODO: Make all success colors this green: #3d7 ??
    cursor: success;
  }

  .progress-success:hover {
    color: $btn-success-color;
    background-color: $btn-success-bg;
    border-color: $btn-success-border;
  }

  .progress-danger {
    @extend .btn;
    @extend .btn-danger;
    cursor: danger;
    border-color: $btn-danger-color;
  }

  .progress-danger:hover {
    color: $btn-danger-color;
    background-color: $btn-danger-bg;
    border-color: $btn-danger-border;
  }

  .progress-warning {
    @extend .btn;
    @extend .btn-warning;
    cursor: warning;
  }

  .progress-warning:hover {
    color: $btn-warning-color;
    background-color: $btn-warning-bg;
    border-color: $btn-warning-border;
  }

  .progress-default,
  .progress-info,
  .progress-success,
  .progress-danger,
  .progress-warning {
    // padding: 0px;
    // margin-top: 0px;
    width: 12.5%;
    height: 8px;
    // margin: -6px 0px 4px 0px;
    border-radius: 0px;
    border: none;
  }

  .submit {
    display: none;
    width: 90px; // small: 45, large: 90
    height: 90px;
    margin: 0 auto -10px auto;
    background: #fff;
    border-radius: 100%;
    border: 1px solid #fff;
    font-size: 24px;
    cursor: default;
    box-shadow: 0 0 0 7px #fff;
  }

  button.complete {
    background: #3d7;
    border-color: #3d7;
    color: #fff;
    transition: 0.2s ease-in;

    i.fa-check {
      margin-top: 7px;
    }
  }

  button.error {
    @extend .btn-danger;
    transition: 0.1s ease-in;
    transition: 0.1s ease-out;

    i.fa-times {
      margin-top: 7px;
    }
  }

  .submit-button-add-margin-bottom {
    margin-bottom: 8px;
  }

  .status-button {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    z-index: 10;
  }
}
