#accommodation-page {
  h3 {
    margin-top: 36px;
  }

  #zoom-in-demo {
    img {
      width: 80%;
    }
    background-color: #ececec;
    margin-bottom: 32px;
  }
  .product-display {
    margin-bottom: 32px;
  }
}
