// *{margin:0;padding:0;}

// body{
//   background:white;
//   overflow:hidden;
// }

// -webkit-transform: rotate(270deg); /* Safari and Chrome */
// -moz-transform: rotate(270deg);   /* Firefox */
// -ms-transform: rotate(270deg);   /* IE 9 */
// -o-transform: rotate(270deg);   /* Opera */
// transform: rotate(270deg);
#hands-container {
  width: 240px;
  height: 420px;
  margin-top: 0px;
  position: relative;
  -webkit-animation: peek 1s linear 1;
  -moz-animation: peek 1s linear 1;
  animation: peek 1s linear 1;

  // debugging
  border: 1px solid black;

  // body
  // background:white;
  // overflow:hidden;

  #arm {
    // new
    position: absolute;
    bottom: 0px;

    width: 200px;
    height: 100px;
    background: #56c540;
    border-radius: 30px 30px 0px 0px;
    // float:left;
    // position: relative;
    z-index: 2;
  }
  .sleeve {
    width: 160px;
    height: 50px;
    background: #56c540;
    float: left;
    // margin:0px 20px 0 0px;
    list-style: none;
    border-radius: 10px 10px 0px 0;
    z-index: 2;

    position: absolute;
    bottom: 90px;
    left: 20px;
  }
  .sleeve div {
    // display: inline;
    width: 3px;
    height: 30px;
    background: #569f40;
    position: relative;
    float: left;
    margin-left: 30px;
    margin-top: 10px;
  }
  .sleeve div:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 30px;
    background: #569f40;
    right: 10px;
  }
  .sleeve div:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 30px;
    background: #569f40;
    left: 10px;
  }
  .inside {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #242424;
    margin: 10px auto;
    position: relative;
    // z-index:4;
  }
  .inside:before {
    content: '';
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 30px solid #93714b;
    left: 34px;
    top: 10px;
    outline: 1px solid transparent;
  }
  .inside:after {
    content: '';
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 30px solid #93714b;
    left: 34px;
    top: 10px;
    -webkit-transform-origin: bottom;
    -webkit-transform: rotate(90deg);
    transform-origin: bottom;
    transform: rotate(90deg);
    -moz-transform-origin: bottom;
    -moz-transform: rotate(90deg);
  }
  .mid {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 32.5px;
    background: #93714b;
  }
  .mid:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 30px;
    background: #93714b;
    top: -4px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
  }

  .hand {
    height: 200px;
    width: 150px;
    background: #f8cdae;
    position: absolute;
    z-index: 1;
    bottom: 70px;
    left: 25px;
  }
  .small-fing {
    width: 34.5px;
    height: 100px;
    background: #f8cdae;
    position: absolute;
    bottom: 198px;
    left: 0px;
    // margin-bottom:4px;
  }
  .small-fing:before {
    content: '';
    position: absolute;
    height: 34.5px;
    width: 34.5px;
    border-radius: 50%;
    background: #f8cdae;
    bottom: 80px;
  }
  .ring-finger {
    width: 34.5px;
    height: 120px;
    background: #f8cdae;
    position: absolute;
    left: 38.5px; // 34.5 + 4px;
    bottom: 198px;
    // margin-top:4px;
  }
  .ring-finger:before {
    content: '';
    position: absolute;
    height: 34.5px;
    width: 34.5px;
    border-radius: 50%;
    background: #f8cdae;
    bottom: 100px;
  }
  .pointer-finger {
    width: 34.5px;
    height: 120px;
    background: #f8cdae;
    position: absolute;
    left: 115.5px; // 34.5 + 4px;
    bottom: 198px;
    // margin-top:4px;
  }
  .pointer-finger:before {
    content: '';
    position: absolute;
    height: 34.5px;
    width: 34.5px;
    border-radius: 50%;
    background: #f8cdae;
    bottom: 100px;
  }
  .mid-fing {
    width: 34.5px;
    height: 140px;
    background: #f8cdae;
    position: relative;
    bottom: 132px;
    // margin-top:4px;
    left: 77px;
  }
  .mid-fing:before {
    content: '';
    position: absolute;
    height: 34.5px;
    width: 34.5px;
    border-radius: 50%;
    background: #f8cdae;
    bottom: 120px;
  }
  .thumb {
    width: 40px;
    height: 80px;
    background: #f8cdae;
    position: relative;
    bottom: 130px;
    left: 140px;
    -webkit-transform: rotate(225deg); // formerly 225deg
    transform: rotate(225deg);
    -moz-transform: rotate(225deg);
  }
  .thumb:before {
    content: '';
    position: absolute;
    height: 50px;
    width: 40px;
    border-radius: 50%;
    background: #f8cdae;
    left: 0;
    top: 55px;
  }

  @-webkit-keyframes peek {
    from {
      left: -800px;
    }
    to {
      left: 0px;
    }
  }
  @keyframes peek {
    from {
      left: -800px;
    }
    to {
      left: 0px;
    }
  }
  @-moz-keyframes peek {
    from {
      left: -800px;
    }
    to {
      left: 0px;
    }
  }
}

#hands-container2 {
  width: 420px;
  height: 240px;
  margin-top: 0px;
  position: relative;
  -webkit-animation: peek 1s linear 1;
  -moz-animation: peek 1s linear 1;
  animation: peek 1s linear 1;

  // debugging
  border: 1px solid black;

  // body
  // background:white;
  // overflow:hidden;

  #arm {
    width: 100px;
    height: 200px;
    background: #56c540;
    border-radius: 0 30px 30px 0;
    float: left;
    position: relative;
    z-index: 2;
  }
  .sleeve {
    position: relative;
    width: 50px;
    height: 160px;
    background: #56c540;
    float: left;
    margin: 20px 0 0 -10px;
    list-style: none;
    border-radius: 0 10px 10px 0;
    z-index: 2;
  }
  .sleeve li {
    width: 30px;
    height: 3px;
    background: #569f40;
    position: relative;
    margin-left: 10px;
    margin-top: 30px;
  }
  .sleeve li:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background: #569f40;
    top: 10px;
  }
  .sleeve li:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 3px;
    background: #569f40;
    bottom: 10px;
  }
  .inside {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #242424;
    margin: 10px auto;
    position: relative;
    // z-index:4;
  }
  .inside:before {
    content: '';
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 30px solid #93714b;
    left: 34px;
    top: 10px;
    outline: 1px solid transparent;
  }
  .inside:after {
    content: '';
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 30px solid #93714b;
    left: 34px;
    top: 10px;
    -webkit-transform-origin: bottom;
    -webkit-transform: rotate(90deg);
    transform-origin: bottom;
    transform: rotate(90deg);
    -moz-transform-origin: bottom;
    -moz-transform: rotate(90deg);
  }
  .mid {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 32.5px;
    background: #93714b;
  }
  .mid:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 30px;
    background: #93714b;
    top: -4px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -moz-transform: rotate(45deg);
  }

  .hand {
    height: 150px;
    width: 200px;
    background: #f8cdae;
    position: relative;
    z-index: 1;
    left: 60px;
    top: 25px;
  }
  .small-fing {
    width: 100px;
    height: 34.5px;
    background: #f8cdae;
    position: relative;
    left: 198px;
    margin-bottom: 4px;
  }
  .small-fing:before {
    content: '';
    position: absolute;
    height: 34.5px;
    width: 34.5px;
    border-radius: 50%;
    background: #f8cdae;
    left: 80px;
  }
  .finger {
    width: 120px;
    height: 34.5px;
    background: #f8cdae;
    position: relative;
    left: 198px;
    margin-top: 4px;
  }
  .finger:before {
    content: '';
    position: absolute;
    height: 34.5px;
    width: 34.5px;
    border-radius: 50%;
    background: #f8cdae;
    left: 100px;
  }
  .mid-fing {
    width: 140px;
    height: 34.5px;
    background: #f8cdae;
    position: relative;
    left: 198px;
    margin-top: 4px;
  }
  .mid-fing:before {
    content: '';
    position: absolute;
    height: 34.5px;
    width: 34.5px;
    border-radius: 50%;
    background: #f8cdae;
    left: 120px;
  }
  .thumb {
    width: 40px;
    height: 80px;
    background: #f8cdae;
    position: relative;
    left: 130px;
    top: -30px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
  }
  .thumb:before {
    content: '';
    position: absolute;
    height: 50px;
    width: 40px;
    border-radius: 50%;
    background: #f8cdae;
    left: 0;
    top: 55px;
  }

  @-webkit-keyframes peek {
    from {
      left: -800px;
    }
    to {
      left: 0px;
    }
  }
  @keyframes peek {
    from {
      left: -800px;
    }
    to {
      left: 0px;
    }
  }
  @-moz-keyframes peek {
    from {
      left: -800px;
    }
    to {
      left: 0px;
    }
  }
}
