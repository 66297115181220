// All bootstrap overrides go here
//  Use @import to import SASS files

//** Padding between columns. Gets divided in half for the left and right.
// **** May want to adjust container sizes as well
$grid-columns: 24;
// $grid-gutter-width: 0rem;

// Formulas copy and pasted from Bootstrap Variables
$container-desktop: calc(940/16 * 1rem);
$container-md: $container-desktop;

// Eliminate the lg container
$container-large-desktop: $container-md;
$container-lg: $container-md;

$screen-xs: calc(1/16 * 1rem);
$screen-sm: calc(2/16 * 1rem);
$screen-md: calc(3/16 * 1rem);

// A reusable variable for vertical rhythm
$baseline-height: 1.6;

// Fonts
@import url(https://fonts.googleapis.com/css?family=Khula:300,400,600);
// Note: Roboto weight@800 is used in our document production instructions to have an exaggerated bold (so that
// students don't miss it and lose marks)
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Indie+Flower);
// Roboto Mono at 450 weight is the right look/feel. We use this font as the default in
// typing environments (i.e. timings, lessons, etc)
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@450&display=swap);


// Overriding the defaults used for .fw-bold helpers and <b></b> tags respectively (to mimic what we had
// before switching from Khula to Roboto)
$font-weight-bold: 500;
$font-weight-bolder: 500;


// overriding default bold size
$table-th-font-weight: $font-weight-bold;

// This is to mimic the look we had when using Khula, because Khula does not have 500 weight,
// which is the default weight used in headings, so our headings in Typist just ignored the semi-bold look
$headings-font-weight: 400;

// Using the Bootstrap 5.1 native font stack, but moving Roboto to the top, because that is our main font
$font-family-sans-serif:
  // "Poppins",
  // Android + our main font
  Roboto,
  // Cross-platform generic font family (default user interface font)
  system-ui,
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
  // Windows
  "Segoe UI",
  // Basic web fallback
  "Helvetica Neue", Arial,
  // Linux
  "Noto Sans",
  "Liberation Sans",
  // Sans serif fallback
  sans-serif,
  // Emoji fonts
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// $font-size-base: 1rem;
// Navbar
$navbar-default-bg: #2da8e3;
$navbar-default-link-color: #fff;
$navbar-height: calc(70/16 * 1rem);

$typist-blue: #2da8e3;
$primary: #3174af;
$info: #128091;
$success: #218737;

a.navbar-brand {
  font-size: calc(30/16 * 1rem);
  letter-spacing: calc(1/16 * 1rem);
  font-weight: 700;
}

a.navbar-brand:hover {
  text-decoration: none;
}


$navbar-default-link-hover-color: $navbar-default-link-color;
$navbar-default-brand-hover-color: $navbar-default-link-color;

// Hover
$navbar-default-link-hover-color: #fff;
$navbar-default-link-hover-bg: #1493d1;
$nav-pills-border-radius: calc(20/16 * 1rem);
$nav-pills-active-link-hover-bg: #1493d1;
$nav-pills-active-link-hover-color: #fff;

// Active
$navbar-default-link-active-bg: #1493d1;
$navbar-default-link-active-color: #fff;

// main div.container { margin-top: 110/16 * 1rem; }

// any element that has ng-click should display a pointer
[ng-click],
[data-ng-click],
[x-ng-click] {
  cursor: pointer;
}

// NOTE: in Bootstrap 5, h1-h6 css classes are no longer just size adjustments (now there are fs-1 ... fs-6
// classes for this purpose)
// the h1 to h6 classes in bs5 are now extending the h1-h6 tags (https://github.com/twbs/bootstrap/blob/v5.1.3/scss/_type.scss)
// this means the definitions below for `h6.subtitle` will also generate `.h6.subtitle`, since `.h6` extends `h6`
// (see https://sass-lang.com/documentation/at-rules/extend#:~:text=When%20one%20class%20selector%20extends,%3D%22error%22%20as%20well.)
// therefore, any previously used .h1-.h6 classes should switch to .fs-1 - .fs-6 classes, to avoid undesired effects due to
// .h1-.h6 classes inheriting from h1-h6 tags

h5.subtitle {
  color: #999;
  margin-top: calc(-6/16 * 1rem);
}

h6.subtitle {
  color: #999;
}

span.roboto-mono-font {
  font-family: 'Roboto Mono', monospace !important;
}

.roboto-mono-font {
  font-family: 'Roboto Mono', monospace !important;
}

/* 
  Allow angular.js to be loaded in body, hiding cloaked elements until 
  templates compile.  The !important is important given that there may be 
  other selectors that are more specific or come later and might alter display.  
 */
[ng\:cloak],
[ng-cloak],
.ng-cloak {
  display: none !important;
}

/* my stuff */
button {
  .show-when-enabled { display: initial; }
  .show-when-disabled { display: none; };
  &[disabled] {
    .show-when-enabled { display: none; };
    .show-when-disabled { display: initial; };
  }
}