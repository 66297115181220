// Place all the styles related to the PracticeTextController controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
#practice-text-admin-view {
  .ids-list {
    line-height: 16px;

    span.id-link {
      display: inline-block;
      font-size: 12px;
      width: 24px;
    }
    span.id-range {
      display: inline-block;
      font-size: 12px;
      width: 60px;
    }
  }

  .char-count {
    font-size: 18px;
  }
  .char-count-sub {
    font-size: 14px;
    height: 18px;
    margin-top: -6px;
  }
  .create-new {
    a {
      position: absolute;
      top: 14px;
      font-weight: $font-weight-bold;
      font-size: 18px;
    }
  }

  form.practice-text {
    input {
      font-size: 14px;
    }
    textarea {
      font-size: 14px;
      width: 100%;
    }
    .radio-label {
      font-weight: normal;
      margin-top: -2px;
      margin-bottom: 0px;
      font-size: 14px;
    }
    .errors {
      font-size: 14px;
      color: red;
    }
    .success {
      font-size: 14px;
      color: green;
    }
  }
}
