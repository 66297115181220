// global
#error-success-messages {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: -28px;
  margin-bottom: 10px;
  p {
    margin-top: 8px;
    margin-bottom: 0px;
  }
}
// Specific to error messages
