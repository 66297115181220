// Each key has a width of 6 units
// Some keys can have a width of more than 6 units

$key-width: 34px; // 34
$font-size: 14px;
$space-between-keys: 3px;
$space-between-rows: 3px;

$border-width: 1px;
$key-border-radius: 4px;

#hands-over-keyboard {
  display: none; // hide by default. shown in controller.
  content: '';
  background: url('images/hands-over-keyboard/hands-over-keyboard-shade-2-1300x1000.png');
  background-size: cover;
  opacity: 0.7;
  height: 411px;
  width: 586px;
  top: -44px; // animate to -44px
  left: -34px;
  // bottom: 0;
  // right: 0;
  position: absolute;
  z-index: 1;
}

// Extra padding at the bottom such that the hands over keyboard can be displayed
.keyboard-padding-bottom {
  padding-bottom: 7em;
}

#keyboard {
  font-size: 0;
  margin-bottom: 12px;

  // Disable highlighting
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .key {
    display: inline-block;
    height: $key-width - $border-width;
    margin-right: $space-between-keys;
    // background-color: #fafafa;

    -moz-border-radius: $key-border-radius;
    border-radius: $key-border-radius;
    overflow: hidden;
  }

  // To help animate
  .key-styling {
    color: #696969;
    border: 1px solid #999;

    -webkit-transition: all 80ms ease-out;
    -moz-transition: all 80ms ease-out;
    -ms-transition: all 80ms ease-out;
    -o-transition: all 80ms ease-out;
    transition: all 80ms ease-out;
  }

  div.pri {
    font-weight: 500;
    color: #2a2a2a;
    font-size: $font-size + 3;
    padding-top: calc(($key-width - $font-size - 6px)/2 - 3px) ;
  }

  div.sec {
    color: #545454;
    padding-top: calc(($key-width - $font-size - 2px)/2 - 2px);
    font-size: $font-size - 2;
  }

  div.ter {
    color: #757575;
    font-size: $font-size - 2;
    padding-top: calc(($key-width - $font-size - 2px)/2 - 3px);
  }

  div.last-key {
    margin-right: 0px;
  }

  .key-6 {
    width: ($key-width - $border-width);
  }

  .key-7 {
    width: ($key-width - $border-width) * 1.2;
  }

  .key-8 {
    width: ($key-width - $border-width) * 1.5;
  }

  .key-10 {
    width: ($key-width - $border-width) * 1.8;
  }

  .key-12 {
    width: ($key-width - $border-width) * 2.3333;
  }

  .key-32 {
    width: ($key-width - $border-width) * 6;
  }

  .digit {
    position: relative;
    text-align: center;
  }

  .keyboard-row {
    margin-bottom: $space-between-rows;
  }

  div.active {
    color: white;
    background-color: #bdbdbd;
    // background-color: #36abe3;
    // opacity: 0.6;
    border-color: #2a2a2a;
  }
}

// styling for the interactive keyboard --------------------------------------------------------
// ---------------------------------------------------------------------------------------------
//
//   - We have two keyboards: #keyboard-interactive (sits below) #keyboard-shadow
//   - These two keyboards allow us to position hand images between, so that we can sense
//       mouse hovers without being ubstructed by the finger images
//   - We support the interactive keyboard seperately since there's likely a heavy load placed
//       on angular, and it may slow down a timed writing.

#keyboard-interactive {
  font-size: 0;
  margin-bottom: 12px;

  // Disable highlighting
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .key {
    display: inline-block;
    height: $key-width - $border-width;
    margin-right: $space-between-keys;
    // background-color: #fafafa;

    -moz-border-radius: $key-border-radius;
    border-radius: $key-border-radius;
    overflow: hidden;
  }

  // this class is added when the shadow key, positioned above, it hovered
  .key-hovered {
    background-color: #c3ebfe;
  }

  // To help animate
  .key-styling {
    color: #696969;
    border: 1px solid #999;

    -webkit-transition: all 80ms ease-out;
    -moz-transition: all 80ms ease-out;
    -ms-transition: all 80ms ease-out;
    -o-transition: all 80ms ease-out;
    transition: all 80ms ease-out;
  }

  div.pri {
    font-weight: 500;
    color: #2a2a2a;
    font-size: $font-size + 3;
    padding-top: calc(($key-width - $font-size - 6px)/2 - 3px);
  }

  div.sec {
    color: #7e7e7e;
    padding-top: calc(($key-width - $font-size - 2px)/2 - 2px);
    font-size: $font-size - 2;
  }

  div.ter {
    color: #bdbdbd;
    font-size: $font-size - 2;
    padding-top: calc(($key-width - $font-size - 2px)/2 - 3px);
  }

  div.last-key {
    margin-right: 0px;
  }

  .key-6 {
    width: ($key-width - $border-width);
  }

  .key-7 {
    width: ($key-width - $border-width) * 1.2;
  }

  .key-8 {
    width: ($key-width - $border-width) * 1.5;
  }

  .key-10 {
    width: ($key-width - $border-width) * 1.8;
  }

  .key-12 {
    width: ($key-width - $border-width) * 2.3333;
  }

  .key-32 {
    width: ($key-width - $border-width) * 6;
  }

  .digit {
    position: relative;
    text-align: center;
  }

  .keyboard-row {
    margin-bottom: $space-between-rows;
  }

  div.active {
    // color: white;
    background-color: #3daadf;
    // background-color: #36abe3;
    // opacity: 0.6;
    border-color: #3daadf;
  }

  // we add this class in here since our JSX Component only uses this id
  div.shadowActive {
    color: white;
  }
}

// this keyboard overlays (on top) the #keyboard-interactive ----------------------------
#shadow-keyboard {
  position: absolute;
  top: 0; // need this to properly position the shadow keyboard as an overlay
  z-index: 10;

  font-size: 0;
  // margin-left: 161px;
  margin-bottom: 12px;

  // Disable highlighting
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .key {
    display: inline-block;
    height: $key-width - $border-width;
    margin-right: $space-between-keys;
    // background-color: #fafafa;

    -moz-border-radius: $key-border-radius;
    border-radius: $key-border-radius;
    overflow: hidden;
  }

  // To help animate
  .key-styling {
    color: blue;
    border: 1px solid transparent;

    -webkit-transition: all 80ms ease-out;
    -moz-transition: all 80ms ease-out;
    -ms-transition: all 80ms ease-out;
    -o-transition: all 80ms ease-out;
    transition: all 80ms ease-out;
  }

  div.pri {
    font-weight: 500;
    color: #2a2a2a;
    font-size: $font-size + 3;
    padding-top: calc(($key-width - $font-size - 6px)/2 - 3px);
  }

  div.sec {
    color: #7e7e7e;
    padding-top: calc(($key-width - $font-size - 2px)/2 - 2px);
    // font-size: $font-size - 2;
  }

  div.ter {
    color: #bdbdbd;
    font-size: $font-size - 2;
    padding-top: calc(($key-width - $font-size - 2px)/2 - 3px);
  }

  div.last-key {
    margin-right: 0px;
  }

  .key-6 {
    width: ($key-width - $border-width);
  }

  .key-7 {
    width: ($key-width - $border-width) * 1.2;
  }

  .key-8 {
    width: ($key-width - $border-width) * 1.5;
  }

  .key-10 {
    width: ($key-width - $border-width) * 1.8;
  }

  .key-12 {
    width: ($key-width - $border-width) * 2.3333;
  }

  .key-32 {
    width: ($key-width - $border-width) * 6;
  }

  .digit {
    position: relative;
    text-align: center;
  }

  .keyboard-row {
    margin-bottom: $space-between-rows;
  }

  div.active {
    color: white;
    background: none;
    border: 1px solid transparent;
    // background-color: #36abe3;
    // opacity: 0.6;
    // border-color: #2a2a2a;
  }
}
