.navbar {
  #testing-nav {
    color: #fff;

    .testing-student-name {
      font-weight: 600;
      font-size: calc(64/16 * 1rem);
    }
  }

  /*
   * Notifications drop down menu
   */
  #notifications-dropdown.dropdown-menu {
    // Max width for the dropdown menu
    width: calc(432/16 * 1rem);
    // Prevent menu going offscreen if the list is too large
    max-height: 90vh;
    // If list too large, rely on a scrollbar instead
    overflow-y: auto;

    // A single notification card element
    .dropdown-card {
      // Tighten up the vertical line spacing within card text
      line-height: $line-height-base * 0.8;

      .expire-information {
        opacity: 0.6; // Consider moving this to a list of custom modifiers, as Bootstrap 4 doesn't provide opacity helpers
      }

      // Custom hover animation
      &:hover {
        color: $white !important;

        // Expired notifications have a grey background on hover
        background-color: $gray-600;

        &.active-notification {
          // Active notifications have a green background
          background-color: $success;
        }

        // The hover effect for the notification circle to
        .notification-status-circle {
          .outer-circle {
            .inner-circle {
              transform: scale(1);
            }
          }
        }
      }

      // notification status circle has 2 nested circles, differently colored circles.
      // Upon hovering, the inner tested circle simply expands
      .notification-status-circle {
        display: flex;
        justify-content: center;

        .outer-circle {
          background-color: $gray-600;
          content: '';
          width: 1em;
          height: 1em;
          border-radius: 50%;

          .inner-circle {
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 50%;
            transform: scale(0);
          }
        }
      }
    }

    // Active notifications are visually more animated and the background color is green
    .active-notification {
      .notification-status-circle {
        .outer-circle {
          background-color: $success;

          .inner-circle {
            // Have an fluid animation only for active notifications,
            // for non-active it is a simple switch between states
            transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          }
        }
      }
    }
  }
}

#username-dropdown-nav {
  ul.dropdown-menu {
    width: 280px;
    margin-top: -10px;
    font-size: 15px;
  }

  ul.dropdown-menu::before {
    right: 32px;
  }

  ul.dropdown-menu::after {
    right: 32px;
  }
}

// Add the triangle that points to the keyboard in nav bar
// Localizing the custom arrow to just the navbar for now, as it has unintended effects in the rest of the app
.navbar .dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.navbar .dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: '';
}

.navbar .navbar-right > li > .dropdown-menu:before,
.navbar .nav > li > .dropdown-menu.navbar-right:before {
  right: 35px;
  left: auto;
}

.navbar .navbar-right > li > .dropdown-menu:after,
.navbar .nav > li > .dropdown-menu.navbar-right:after {
  right: 36px;
  left: auto;
}
