// Payment forms
.subscription-plan {
  .special-offer {
    margin-top: 4px;
    font-weight: $font-weight-bold;
  }

  .price {
    font-size: 38px;
    margin-top: -8px;
    height: 96px;
  }

  .payment-button {
    margin-top: -24px;
    margin-bottom: -10px;
  }

  // #access-code-button {
  // 	padding-top: 4px;
  // 	padding-bottom: 4px;
  // }

  // Stripe's CSS taken from their form
  .stripe-button-el {
    overflow: hidden;
    display: inline-block;
    visibility: visible !important;
    background-image: -webkit-linear-gradient(#28a0e5, #015e94);
    background-image: -moz-linear-gradient(#28a0e5, #015e94);
    background-image: -ms-linear-gradient(#28a0e5, #015e94);
    background-image: -o-linear-gradient(#28a0e5, #015e94);
    background-image: -webkit-linear-gradient(#28a0e5, #015e94);
    background-image: -moz-linear-gradient(#28a0e5, #015e94);
    background-image: -ms-linear-gradient(#28a0e5, #015e94);
    background-image: -o-linear-gradient(#28a0e5, #015e94);
    background-image: linear-gradient(#28a0e5, #015e94);
    -webkit-font-smoothing: antialiased;
    border: 0;
    padding: 1px;
    text-decoration: none;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: pointer;
  }

  .stripe-button-el::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  .stripe-button-el span {
    display: block;
    position: relative;
    padding: 0 12px;
    height: 30px;
    line-height: 30px;
    background: #1275ff;
    background-image: -webkit-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
    background-image: -moz-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
    background-image: -ms-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
    background-image: -o-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
    background-image: -webkit-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
    background-image: -moz-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
    background-image: -ms-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
    background-image: -o-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
    background-image: linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
    font-size: 14px;
    color: #fff;
    font-weight: $font-weight-bold;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    -ms-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
  }

  .stripe-button-el:not(:disabled):active,
  .stripe-button-el.active {
    background: #005d93;
  }

  .stripe-button-el:not(:disabled):active span,
  .stripe-button-el.active span {
    color: #eee;
    background: #008cdd;
    background-image: -webkit-linear-gradient(#008cdd, #008cdd 85%, #239adf);
    background-image: -moz-linear-gradient(#008cdd, #008cdd 85%, #239adf);
    background-image: -ms-linear-gradient(#008cdd, #008cdd 85%, #239adf);
    background-image: -o-linear-gradient(#008cdd, #008cdd 85%, #239adf);
    background-image: -webkit-linear-gradient(#008cdd, #008cdd 85%, #239adf);
    background-image: -moz-linear-gradient(#008cdd, #008cdd 85%, #239adf);
    background-image: -ms-linear-gradient(#008cdd, #008cdd 85%, #239adf);
    background-image: -o-linear-gradient(#008cdd, #008cdd 85%, #239adf);
    background-image: linear-gradient(#008cdd, #008cdd 85%, #239adf);
    -webkit-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
    -ms-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
    -o-box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
  }

  .stripe-button-el:disabled,
  .stripe-button-el.disabled {
    background: rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
  }

  .stripe-button-el:disabled span,
  .stripe-button-el.disabled span {
    color: #999;
    background: #f8f9fa;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  }

  // end of Stripe's CSS ---------------
}
