// Place all the styles related to the PracticeTextController controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/
#student-dashboard {
  .is-loading {
    opacity: 0.3;
  }

  span.spinner {
    position: absolute;
    top: 22px;
    left: 329px; // 180
    z-index: 1;
  }

  #above-requirements-dashboard {
    .tooltip {
      width: 200px;
    }
  }

  h2 {
    margin-top: 10px;
  }

  #class-selector-form {
    margin-top: 4px;
  }

  .student-dashboard-test-results {
    th,
    td {
      font-size: 15px;
      padding-left: 0px;
      padding-top: 2px;
      padding-bottom: 0px;
    }

    margin-bottom: 4px;

    .see-more {
      height: 22px;
    }
  }

  // practice stats
  .practice-time-dashboard {
    height: 164px;

    .practice-time-bar-graph {
      position: absolute;
      bottom: 20px;
    }

    .rotate-vertically {
      display: block;
      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      -o-transform: rotate(270deg);
      transform: rotate(270deg);
    }

    .chart-title {
      font-size: 14px;
      font-weight: $font-weight-bold;
    }

    .chart-title-vertical {
      position: absolute;
      bottom: 66px;
      left: -54px;
      width: 130px;
      border-bottom: #999 solid 2px;
    }

    .chart-title-horizontal {
      position: absolute;
      bottom: -5px;
      left: -12px;
      border-top: #999 solid 2px;
    }
  }

  // Note: .bar and .gold-background are in two places. Search for them before making changes.
  .bar {
    width: 6%;
    /*margin-right: 1px;*/
    margin-bottom: 2px;
    display: inline-block;
    position: relative;
    background-color: #5bc0de;
    vertical-align: bottom;

    /* animate the bar */
    -webkit-transition: height 1s;
    -moz-transition: height 1s;
    -ms-transition: height 1s;
    -o-transition: height 1s;
    transition: height 1s;

    -webkit-transition-delay: 0.3s;
    /* Safari */
    -webkit-transition-timing-function: linear;
    /* Safari and Chrome */
    transition-timing-function: linear;
    transition-delay: 0.3s;
  }

  // Note: .bar and .gold-background are in two places. Search for them before making changes.
  .gold-background {
    background: repeating-linear-gradient(60deg, #ffd700, #ffd700 3px, #5bc0de 0, #5bc0de 8px);
  }
}

#practice-timings-dashboard {
  padding-left: 20px;
  padding-right: 20px;
}
