.typing-arena {
  /*
  IMPORTANT: 
  There is an issue when the hands appear AND the the height of the web document is now
  larger than the viewport size, hence turning on the scrollbar. The scrollbar causes a "jittery"
  movement when the hands appear and disappear. 

  To fix it, we added an overflow-y hidden property and some extra padding to the keyboard
  container (see keyboard.scss)
  
  For more info, see this article: https://drafts.csswg.org/css2/intro.html#canvas
  */
  overflow-y: hidden;

  .no-underline {
    text-decoration: none;
  }

  // Home page --------------------------------------------
  #open-contests {
    .contest-details {
      li {
        margin-top: 8px;
      }
    }

    .play-button {
      padding-top: 4px;

      p {
        font-size: 14px;
      }
    }

    .fa-star-o {
      color: gold;
    }
  }

  // Modal -------------------------------------------------
  .contest-starting-button {
    h5 {
      font-size: 24px;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    width: 100%;

    h5.small-message {
      font-size: 22px;
    }
  }

  #results-modal {
    #championship-mode-results-modal {
      .fa-trophy {
        font-size: 50px;
      }

      .results-modal-text {
        h3 {
          line-height: 48px;
        }
      }
    }

    #championship-mode-div {
      margin-top: 10px;
    }

    // .title {
    //   color: #999;
    //   font-size: 24px;
    //   font-weight: 600;
    // }
    .score {
      font-size: 32px;
    }

    // .suffix {
    //   color: #999;
    //   font-size: 20px;
    // }
    h3 {
      margin: 6px 0px -8px 0px;
    }

    .scoreboard-main {
      margin-bottom: -14px;

      .title {
        font-size: 16px;
        margin-bottom: -4px;
      }

      .score {
        font-size: 42px;
        letter-spacing: -1px;
      }

      .suffix {
        font-size: 18px;
      }
    }

    .duration-row {
      margin-top: -12px;
      margin-bottom: -18px;
    }

    .duration {
      font-size: 22px;
    }

    .duration-value {
      font-size: 22px;
      font-weight: $font-weight-bold;
    }
  }

  // Compete -----------------------------------------------
  // Score Board
  // TODO: remove this once all occurences have been swapped for BS4 modifiers
  #score-board {
    .title {
      color: #999;
    }

    .score {
      font-size: 24px;
    }

    .suffix {
      color: #999;
    }
  }

  // TODO: Phase out once it is replaced by Bootstrap 4 modifiers
  .message-button {
    h3 {
      margin-top: 12px;
      padding-top: 8px;
      padding-bottom: 6px;
    }
  }

  // Actual text
  #text-to-type {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    :focus {
      outline: none;
    }

    #line-of-text {
      font-family: 'Roboto Mono', monospace;
      font-weight: 450;
      font-size: 24px;
      // This width was determined by creating a line of text with 52 characters (eg. 26 'm' chars, each
      // separated by a space). When using a mono space font, as we currently are, we calculate the width by:
      // 1. Set the font size, open the browser, check the width of each character (e.g. 14.4 px)
      // 2. Find the maxCharsPerLine in typing_input_service.js (e.g. 52).
      // 3. Multiply these two numbers, add a few extra pixels: 14.4 * 52 = 748.8... so we use 752px.
      // 
      // There are a few additional considerations:
      // 1. We need to enure that the OpenDyslexic font can fit this number of characters, too. Its width is 
      //    set directly in the class .opendyslexic-font below.
      // 2. If changing the maxCharsPerLine, the lesson factory must be updated to ensure we're only
      //    displaying one line of text, if desired (which we probably want to do).
      width: 752px;

      .letter {
        margin: 0px 0px;
        padding: 0px 0px;
      }

      .line {
        margin-top: 2px;
      }

      .active {
        border-bottom: 3px solid #999;
      }

      // note: we add border-bottom: none since there's an error where the active
      //  underline won't advance quickly enough, and causes issues. This is a
      //  hacky fix.
      .pressed-correct {
        color: #757575;
        border-bottom: none;
      }

      // note: we add border-bottom: none since there's an error where the active
      //  underline won't advance quickly enough, and causes issues. This is a
      //  hacky fix.
      .pressed-incorrect {
        color: #ff4646;
        border-bottom: none;
      }

      .pressed-incorrect > span.space {
        background-color: #ff9393;
      }

      .pressed-correct > span.space {
        background-color: none;
      }

      div.digit-container {
        display: inline-block;
        height: 38px;
        color: #000000;
      }
    }
  }
}

.opendyslexic-font {
  font-family: 'OpenDyslexic' !important;

  // need to increase the width to account for the new font
  width: 1020px !important;

  .line {
    // extra spacing between lines
    margin-top: 8px !important;
    
    span[data-bs-content='"'],
    span[data-bs-content='”'],
    span[data-bs-content='“'] {
      // quotation marks seem to not render properly in HTML
      padding-left: 7px !important;
      padding-right: 2px !important;
    }
  }
 .letter {
    font-weight: 600 !important;
  }
}