.bronze {
  color: #cd7f32;
}

.silver {
  color: #c0c0c0;
}

.gold {
  color: #ffd700;
}

#lesson {
  .inactive {
    opacity: 0.4;
  }

  .star-row {
    color: #999;
    top: 18px;
  }

  // for the lesson complete modal
  .star-row-modal {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
