.info-card {
  @extend .card;

  div.card-header {
    border-bottom: none;
    background-color: transparent;
  }

  // extend card-header to get a consistent background color
  // @extend .card-header;
  // background-color: rgba(0, 0, 0, 0.015);
  border-width: 2px;

  border-left: 1em solid $info;
  border-color: $info;
  border-radius: 0.4em !important;

  .card-body {
    @extend .py-1;
  }

  .card-header {
    @extend .py-0;
    @extend .mt-2;
    @extend .fw-bold;
  }
}

.success-card {
  @extend .info-card;
  border-color: $success;
}

.warning-card {
  @extend .info-card;
  border-color: $warning;
}
