// From: http://codepen.io/poop/pen/mIfHb
.hotspot {
  position: absolute;
  margin-top: 94px;
  left: 38px;
  top: 50%;
  color: #fff;
  -webkit-animation: none 3s linear infinite;
  -moz-animation: none 3s linear infinite;
  -ms-animation: none 3s linear infinite;
  -o-animation: none 3s linear infinite;
  animation: none 3s linear infinite;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: hotspot-vertical-top;
  -moz-animation-name: hotspot-vertical-top;
  -ms-animation-name: hotspot-vertical-top;
  -o-animation-name: hotspot-vertical-top;
  animation-name: hotspot-vertical-top;
  // custom
  opacity: 0.85;
}

.hotspot-inner {
  position: absolute;
  bottom: 0;
  left: -20px;
  margin-bottom: 10px;
  background: $brand-success;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
  background: $brand-success; // f5b400
}

.hotspot-inner:before {
  content: '';
  position: absolute;
  left: 25px;
  top: -10px;
  margin-left: -5px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid $brand-success; // f5b400
  border-top-color: $brand-success; // f5b400
}

.icn {
  display: inline-block;
  height: 50px;
  width: 260px;
  line-height: 55px;
  font-size: 1.2rem;
}

@-webkit-keyframes hotspot-vertical-top {
  0%,
  30%,
  40%,
  53.3%,
  66.667%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  35% {
    -webkit-transform: translate3d(0, 25px, 0);
    -moz-transform: translate3d(0, 25px, 0);
    -ms-transform: translate3d(0, 25px, 0);
    -o-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  46.667% {
    -webkit-transform: translate3d(0, 12.5px, 0);
    -moz-transform: translate3d(0, 12.5px, 0);
    -ms-transform: translate3d(0, 12.5px, 0);
    -o-transform: translate3d(0, 12.5px, 0);
    transform: translate3d(0, 12.5px, 0);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: translate3d(0, 6.25px, 0);
    -moz-transform: translate3d(0, 6.25px, 0);
    -ms-transform: translate3d(0, 6.25px, 0);
    -o-transform: translate3d(0, 6.25px, 0);
    transform: translate3d(0, 6.25px, 0);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@-moz-keyframes hotspot-vertical-top {
  0%,
  30%,
  40%,
  53.3%,
  66.667%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  35% {
    -webkit-transform: translate3d(0, 25px, 0);
    -moz-transform: translate3d(0, 25px, 0);
    -ms-transform: translate3d(0, 25px, 0);
    -o-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  46.667% {
    -webkit-transform: translate3d(0, 12.5px, 0);
    -moz-transform: translate3d(0, 12.5px, 0);
    -ms-transform: translate3d(0, 12.5px, 0);
    -o-transform: translate3d(0, 12.5px, 0);
    transform: translate3d(0, 12.5px, 0);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: translate3d(0, 6.25px, 0);
    -moz-transform: translate3d(0, 6.25px, 0);
    -ms-transform: translate3d(0, 6.25px, 0);
    -o-transform: translate3d(0, 6.25px, 0);
    transform: translate3d(0, 6.25px, 0);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@-ms-keyframes hotspot-vertical-top {
  0%,
  30%,
  40%,
  53.3%,
  66.667%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  35% {
    -webkit-transform: translate3d(0, 25px, 0);
    -moz-transform: translate3d(0, 25px, 0);
    -ms-transform: translate3d(0, 25px, 0);
    -o-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  46.667% {
    -webkit-transform: translate3d(0, 12.5px, 0);
    -moz-transform: translate3d(0, 12.5px, 0);
    -ms-transform: translate3d(0, 12.5px, 0);
    -o-transform: translate3d(0, 12.5px, 0);
    transform: translate3d(0, 12.5px, 0);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: translate3d(0, 6.25px, 0);
    -moz-transform: translate3d(0, 6.25px, 0);
    -ms-transform: translate3d(0, 6.25px, 0);
    -o-transform: translate3d(0, 6.25px, 0);
    transform: translate3d(0, 6.25px, 0);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@keyframes hotspot-vertical-top {
  0%,
  30%,
  40%,
  53.3%,
  66.667%,
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-animation-timing-function: ease-out;
    -moz-animation-timing-function: ease-out;
    -ms-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  35% {
    -webkit-transform: translate3d(0, 25px, 0);
    -moz-transform: translate3d(0, 25px, 0);
    -ms-transform: translate3d(0, 25px, 0);
    -o-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  46.667% {
    -webkit-transform: translate3d(0, 12.5px, 0);
    -moz-transform: translate3d(0, 12.5px, 0);
    -ms-transform: translate3d(0, 12.5px, 0);
    -o-transform: translate3d(0, 12.5px, 0);
    transform: translate3d(0, 12.5px, 0);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: translate3d(0, 6.25px, 0);
    -moz-transform: translate3d(0, 6.25px, 0);
    -ms-transform: translate3d(0, 6.25px, 0);
    -o-transform: translate3d(0, 6.25px, 0);
    transform: translate3d(0, 6.25px, 0);
    -webkit-animation-timing-function: ease-in;
    -moz-animation-timing-function: ease-in;
    -ms-animation-timing-function: ease-in;
    -o-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}
