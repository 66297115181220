
// bootstrap 5.1+ uses $body-bg as the default for input-bg. We override this
// to use white.
$input-bg: white;

// making our dropdowns a little more wide
$dropdown-min-width: 13rem; // default 10rem


// This re-enables boostrap 4 default behavior
// Auto-scrolling breaks our proofreading marks doc production view (i.e. clicking the list of errors at the top
// does not scroll to the corresponding proofreading mark at the bottom, because due to smooth scrolling animation there is a race condition 
// (we first scroll to element, then scroll a few pixels down to have the proofreading mark centered in view)
// It also creates a different behaviour in our transcription environment, after the modals sequence is over
// It's ok to turn this one off because BS5 turns it off anyways for users asking for reduced motion
$enable-smooth-scroll: false;

/*
  Links are underlined by default (not just on hover), unless they’re part of specific components 
  Bootstrap 5 tweak: do not underline links unless hovered over 
  Note: excluding .dropdown-item and .list-group-item class because without it, navbar dropdown user menu will have underline links on hover as well
*/
a:not(.btn, .dropdown-item, .list-group-item) {
  text-decoration: none;
}
a:not(.btn, .dropdown-item, .list-group-item):hover {
  text-decoration: underline;
}

// removing underlining for button links
button.btn-link {
  text-decoration: none;
}
button.btn-link:hover {
  text-decoration: underline;
}

// Using Bootstrap 4 breakpoints until we want to move to bootstrap 5
// https://getbootstrap.com/docs/4.6/layout/grid/#variables
// https://getbootstrap.com/docs/5.1/layout/grid/#sass
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px,
  // REMOVING BS5 XXL
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  // REMOVING BS5 XXL
);