// globally defined style
a.template-help {
  @extend .text-decoration-none;
  color: #999;

  :hover {
    color: $typist-blue;
  }

  :focus {
    color: $typist-blue;
  }
}

#class-controller {
  input.class-code {
    @extend .typist-section-code-input;
  }

  #join-class {
    span {
      font-size: 34px;
      font-weight: 800;
      color: #999;
      letter-spacing: 1px;
    }

    // button {
    //   margin: 6px auto;
    //   margin-top: -12px;
    //   width: 160px;
    //   padding-top: 10px;
    // }

    // .input {
    //   // clear defaults
    //   -webkit-box-shadow: none;
    //   -moz-box-shadow: none;
    //   box-shadow: none;
    //   -webkit-border-radius: 4px;

    //   -moz-border-radius: 0px;
    //   -ms-border-radius: 0px;
    //   border-radius: 0px;
    //   // Done clearing defaults

    //   height: 40px;
    //   margin: 0 auto 16px;

    //   border: none;
    //   border-bottom: 2px solid #ebebeb;

    //   &:focus {
    //     outline: none;
    //     border-bottom-color: #2da8e3 !important;
    //   }

    //   &:hover {
    //     border-bottom-color: #dcdcdc;
    //   }

    //   &:invalid {
    //     box-shadow: none;
    //   }
    // }

    // .input:-webkit-autofill {
    //   -webkit-box-shadow: 0 0 0 1000px white inset;
    // }
  }

  .padding-left-right-24 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .padding-left-7 {
    padding-left: 7px;
  }

  .section-code {
    position: absolute;
    top: 30px;
    right: 0px;
    color: rgba(120, 120, 120, 1);
    font-weight: 800;
    font-size: 22px;
  }

  .test-duration {
    height: 24px;
    margin: 8px 0 8px 11px;

    select {
      margin-top: -4px;
    }
  }

  .fade-out-table {
    opacity: 0.3;
  }

  .classlist-table {
    tbody {
      border-color: inherit;
    }

    .bg-selected {
      @extend .bg-warning-light;
      td {
        // This is necessary because we apply the background color to one of the cells of a striped BS5 table,
        // and BS5 now adds the gray rows via box-shadow, not background color. If we don't remove the box shadow,
        // both the background color and the shadow will render in the cell, making the background color appear tinted
        box-shadow: none;
      }
    }

    th > span {
      cursor: pointer;
    }

    .tooltip {
      font-size: 13px;
    }

    .select-all-button {
      padding: 1px 6px;
      font-size: 14px;
    }

    .test-button {
      padding: 1px 6px;
      font-size: 14px;
    }

    .test-again-button {
      color: #444;
      position: absolute;
      left: 0px;
      margin-top: 1px;
      border: none;
      transform: translateX(-100%);
    }

    // .test-again-thead {
    //   margin-top: -24px;
    // }

    td {
      // padding: 2px 2px 0px 8px;
      padding-top: 1px; 
      padding-bottom: 1px;
      line-height: 2;
    }

    .border-end {
      border-right: 2px solid #ddd;
    }

    .border-start {
      border-left: 2px solid #ddd;
    }

    .border-end-fix {
      @extend .border-end;
      position: absolute;
      top: 0px;
      right: -3px;
      height: 31px;
      margin-top: -12px;
    }

    font-size: 14px;

    .secondary-row {
      white-space: nowrap;
      position: absolute;
      border-top-width: 0px;
    }

    .col-xs-2-extra-width {
      // increases from 8.333% (xs-2) to 10% for extra room. col-xs-3 (12.5%) was just too much.
      width: 10%;
    }

    .swapable-column {
      select {
        // width at 100% allows us to control column sizes directly with bootstrap classes.
        //   i.e. changing col-xs-2 to col-xs-3 will actually change size
        // width: 100%;

        // background: none;
        // border: none;
        // position: relative;
        // left: -8px;
        // bottom: -3px;
      }

      select:focus {
        outline: none;
      }
    }
  }

  // top table row
  .top-thead {
    th {
      border: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  .lesson-star-small {
    color: #ccc;
  }

  .lesson-star-large {
    font-size: 22px;
    // animations
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
  }

  .lesson-star-large.bronze {
    -webkit-transition-delay: 0s;
    /* Safari */
    transition-delay: 0s;
  }

  .lesson-star-large.silver {
    -webkit-transition-delay: 0.3s;
    /* Safari */
    transition-delay: 0.3s;
  }

  .lesson-star-large.gold {
    -webkit-transition-delay: 0.6s;
    /* Safari */
    transition-delay: 0.6s;
  }

  .bar {
    width: 6%;
    /*margin-right: 1px;*/
    margin-bottom: 1px;
    display: inline-block;
    position: relative;
    background-color: #5bc0de;
    vertical-align: bottom;

    /* animate the bar */
    -webkit-transition: height 1s;
    -moz-transition: height 1s;
    -ms-transition: height 1s;
    -o-transition: height 1s;
    transition: height 1s;

    -webkit-transition-delay: 0.3s;
    /* Safari */
    -webkit-transition-timing-function: linear;
    /* Safari and Chrome */
    transition-timing-function: linear;
    transition-delay: 0.3s;
  }

  .gold-background {
    background: repeating-linear-gradient(60deg, #ffd700, #ffd700 3px, #5bc0de 0, #5bc0de 8px);
  }
}
