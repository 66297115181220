#welcome {
  margin-top: -60px;

  // decrease the top margin if the height is too small
  @media only screen and (max-height: 660px) {
    margin-top: -100px;
  }

  .terms-and-conditions {
    p {
      font-size: 14px;
    }
  }

  .help-block {
    font-size: 14px;
    color: red;
  }

  .welcome-title {
    h1 {
      font-weight: 600 !important;
      font-size: 4rem;
      color: rgb(255, 255, 255);
    }
  }

  // Mostly for heading above radio buttons
  h5 {
    color: #757575;
  }

  hr.signup0 {
    margin-bottom: 10px;
  }

  hr.signup0-bottom {
    margin-top: 14px;
    margin-bottom: 10px;
  }

  div.radio {
    min-height: 34px;

    label > input {
      margin-top: 1px;
    }
  }

  // Mostly for radio buttons
  label {
    color: #757575;
  }

  // Adjust so long college emails fit
  input[type='email'] {
    font-size: 14px;
    padding-left: 9px;
    padding-right: 9px;
  }

  #login-box {
    .opacity-45 {
      opacity: 0.45;
      -webkit-transition: opacity 0.6s ease-in-out;
      -moz-transition: opacity 0.6s ease-in-out;
      -ms-transition: opacity 0.6s ease-in-out;
      -o-transition: opacity 0.6s ease-in-out;
      transition: opacity 0.6s ease-in-out;
    }

    #access-code {
      font-size: 24px;
      padding-left: 2px;
      padding-right: 2px;
      margin-bottom: 4px;
      text-align: center;
      text-transform: uppercase;
    }

    input[type='email'] {
      font-size: 14px;
      padding-left: 9px;
      padding-right: 9px;
    }

    .terms-conditions-button {
      width: 100%;
      height: 50px;
      font-size: 20px;
    }

    position: relative;
    z-index: 1;

    margin-top: 10px;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;

    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);

    h2,
    .input {
      font-family: 'Open Sans', Helvetica, sans-serif;
    }

    h2 {
      text-align: center;
      font-size: 150%;
      color: #757575;
      font-weight: 300;
      margin-bottom: 20px;
    }

    .subtitle {
      // font-size: 150%;
      margin-top: 8px;
      margin-bottom: 8px;
      // margin-bottom: -8px;
      color: #919191;
      font-size: 14px;
      font-weight: 300;
      // padding-left: 15px;
    }

    .forgot-password {
      margin-top: 6px;
      margin-bottom: 10px;
    }

    .signin-button {
      margin-top: 6px;
      margin-bottom: 8px;
    }

    .form-group {
      margin-bottom: 0px;
    }

    .input {
      // clear defaults
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      -webkit-border-radius: 4px;

      -moz-border-radius: 0px;
      -ms-border-radius: 0px;
      border-radius: 0px;
      // Done clearing defaults

      font-size: 95%;

      height: 40px;
      margin: 0 auto 16px;

      border: none;
      border-bottom: 2px solid #ebebeb;

      padding: 0 15px;

      &:focus {
        outline: none;
        border-bottom-color: #2da8e3 !important;
      }

      &:hover {
        border-bottom-color: #dcdcdc;
      }

      &:invalid {
        box-shadow: none;
      }
    }

    .input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
    }

    // Button
    .login-button {
      background-color: #2da8e3;
      color: white;
      height: 50px;
      margin: 10px auto 10px;
      border: none;

      -webkit-box-shadow: 0 3px 0px #ebebeb;
      -moz-box-shadow: 0 3px 0px #ebebeb;
      box-shadow: 0 3px 0px #ebebeb;

      &:hover {
        top: 1px;
        -webkit-box-shadow: 0 2px 0px #ebebeb;
        -moz-box-shadow: 0 2px 0px #ebebeb;
        box-shadow: 0 2px 0px #ebebeb;
      }

      &:active {
        top: 3px;
        box-shadow: none;
      }

      &:focus {
        outline: none;
      }
    }

    .submit {
      width: 45px;
      height: 45px;
      margin: 0 auto -10px auto;
      background: #fff;
      border-radius: 100%;
      border: 1px solid #2da8e3;
      color: #2da8e3;
      font-size: 24px;
      cursor: pointer;
      box-shadow: 0 0 0 7px #fff;
      transition: 0.1s ease-out;
      transition: 0.1s ease-in;

      &:hover,
      &:focus {
        background: #2da8e3;
        color: #fff;
        outline: 0;
      }

      i.fa-long-arrow-right {
        margin-top: 7px;
      }
    }

    .complete {
      background: #3d7;
      border-color: #3d7;
      color: #fff;
      transition: 0.2s ease-in;

      &:hover,
      &:focus {
        background: #3d7;
        color: #fff;
        outline: 0;
      }

      i.fa-check {
        margin-top: 7px;
      }
    }

    .error {
      @extend .btn-danger;
      transition: 0.1s ease-in;
      transition: 0.1s ease-out;

      i.fa-times {
        margin-top: 7px;
      }
    }

    .submit-button-add-margin-bottom {
      margin-bottom: 8px;
    }
  }
}
