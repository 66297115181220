/*
  Migration to Bootstrap 4 from Bootstrap 3. 
  First breaking points are bootstrap variables that no longer exist in the new version. Re-defining 
  them here allows us to run everything on the new bootstrap without it breaking. This way new 
  components can be created using the new features, and old pages/components can be upgraded over 
  time. This file can also be thought of simply as a Bootstrap-3 theme for Bootstrap-4
*/

/***************
 * icons.scss
 **************/
.col-sm-offset-10 {
  @extend .offset-sm-10;
}

/***************
 * homerowtech.scss
 **************/
$brand-info: #5bc0de;
$btn-info-bg: $brand-info;
$btn-info-color: #fff;
$btn-info-border: darken($btn-info-bg, 5%);

$btn-default-color: #333;
$btn-default-bg: #fff;
$btn-default-border: #ccc;

$brand-success: #5cb85c;
$btn-success-bg: $brand-success;
$btn-success-color: #fff;
$btn-success-border: darken($btn-success-bg, 5%);

$brand-danger: #d9534f;
$btn-danger-bg: $brand-danger;
$btn-danger-color: #fff;
$btn-danger-border: darken($btn-danger-bg, 5%);

$brand-warning: #f0ad41;
$btn-warning-bg: $brand-warning;
$btn-warning-color: #fff;
$btn-warning-border: darken($btn-warning-bg, 5%);

// bootstrap-3: generated using .button-variant() in bootstrap3 less/mixins/buttons.less
.btn-default {
  color: $btn-default-color;
  background-color: $btn-default-bg;
  border-color: $btn-default-border;
  // ... pseudo classes missing. eg. hover, active, etc
}

/***************
 * nav.scss
 **************/
$padding-large-horizontal: calc(12/16 * 1rem);
$gray-light: lighten(#000, 46.7%); // bootstrap-3: lighten($gray-base, 46.7%)

/***************
 * test_centre.scss
 **************/
$gray-dark: lighten(#000, 20%); // bootstrap-3: lighten($gray-base, 20%)
$gray: lighten(#000, 33.5%); // bootstrap-3: lighten($gray-base, 33.5%)
$gray-lighter: lighten(#000, 93.5%); //bootstrap-3: lighten($gray-base, 93.5%)
$padding-large-vertical: 10px;
$brand-primary: darken(#428bca, 6.5%);
$padding-xs-horizontal: 5px;
$padding-base-vertical: 6px;
$font-size-small: ceil((14px * 0.85)); // bootstrap-3: ceil($font0size-base * 0.85)

/***************
 * doc_production.scss
 **************/
$padding-small-horizontal: 10px;
$input-border: #ccc;
$font-size-large: ceil((14px * 1.25));

/***************
 * doc_production.scss
 **************/
$gray-darker: lighten(#000, 13.5%); // bootstrap-3: ceil($gray-darker, 13.5%)
