// Variables
$icon-size-lg: calc(80/16 * 1rem);
$icon-title-height: calc(20/16 * 1rem);
$icon-title-margin-top: calc(6/16 * 1rem);

div.icon-box {
  height: ($icon-size-lg + $icon-title-height + $icon-title-margin-top); // Must fit both icon and title
  width: $icon-size-lg;
  margin-bottom: calc(44/16 * 1rem);
  .disabled {
    opacity: 0.5;
  }

  .tooltip-inner {
    max-width: calc(230/16 * 1rem);
    /* If max-width does not work, try using width instead */
    width: calc(230/16 * 1rem);
  }
}

.icon-box .icon-background {
  height: $icon-size-lg;
  width: $icon-size-lg;
}

.icon-background {
  background-color: #faa919;
  background: -moz-linear-gradient(
    -45deg,
    #fbb430 0,
    #fbb430 26%,
    #faa919 26%,
    #faa919 39%,
    #fbb430 39%,
    #fbb430 52%,
    #faa919 52%,
    #faa919 57%,
    #fbb430 57%,
    #fbb430 78%,
    #faa919 78%,
    #faa919 90%,
    #fbb430 90%,
    #fbb430 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    color-stop(0, #fbb430),
    color-stop(26%, #fbb430),
    color-stop(26%, #faa919),
    color-stop(39%, #faa919),
    color-stop(39%, #fbb430),
    color-stop(52%, #fbb430),
    color-stop(52%, #faa919),
    color-stop(57%, #faa919),
    color-stop(57%, #ffc10d),
    color-stop(78%, #ffc10d),
    color-stop(78%, #faa919),
    color-stop(90%, #faa919),
    color-stop(90%, #ffc10d),
    color-stop(100%, #ffc10d)
  );
  background: -webkit-linear-gradient(
    -45deg,
    #fbb430 0,
    #fbb430 26%,
    #faa919 26%,
    #faa919 39%,
    #fbb430 39%,
    #fbb430 52%,
    #faa919 52%,
    #faa919 57%,
    #fbb430 57%,
    #fbb430 78%,
    #faa919 78%,
    #faa919 90%,
    #fbb430 90%,
    #fbb430 100%
  );
  background: -o-linear-gradient(
    -45deg,
    #fbb430 0,
    #fbb430 26%,
    #faa919 26%,
    #faa919 39%,
    #fbb430 39%,
    #fbb430 52%,
    #faa919 52%,
    #faa919 57%,
    #fbb430 57%,
    #fbb430 78%,
    #faa919 78%,
    #faa919 90%,
    #fbb430 90%,
    #fbb430 100%
  );
  background: -ms-linear-gradient(
    -45deg,
    #fbb430 0,
    #fbb430 26%,
    #faa919 26%,
    #faa919 39%,
    #fbb430 39%,
    #fbb430 52%,
    #faa919 52%,
    #faa919 57%,
    #fbb430 57%,
    #fbb430 78%,
    #faa919 78%,
    #faa919 90%,
    #fbb430 90%,
    #fbb430 100%
  );
  background: linear-gradient(
    135deg,
    #fbb430 0,
    #fbb430 26%,
    #faa919 26%,
    #faa919 39%,
    #fbb430 39%,
    #fbb430 52%,
    #faa919 52%,
    #faa919 57%,
    #fbb430 57%,
    #fbb430 78%,
    #faa919 78%,
    #faa919 90%,
    #fbb430 90%,
    #fbb430 100%
  );
}

.icon-background {
  border-radius: calc(200/16 * 1rem);
}

// DEPRECATED because using Bootstrap 4 helpers instead
// .icon-title {
//   @extend .text-center;

//   margin-top: $icon-title-margin-top;
//   font-weight: 400;
//   font-size: 16/16 * 1rem;
//   color: #3c3c3c
// }

// ICONS
.combo-icon {
  $icon-size: calc($icon-size-lg - $icon-size-lg * 0.45);

  // background-image: url("images/combo-icon.png");
  background-size: $icon-size $icon-size;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
  span {
    font-size: 3rem;
    font-weight: 800;
    color: white;
    line-height: calc(78/16 * 1rem);
  }
  // padding-top: ($icon-size-lg - 50)/2;
}

.one-icon {
  @extend .col-sm-offset-10;
  // margin-left: 42%;
}

.two-icons {
  margin-left: 29%;
  div.icon-box {
    margin-right: 12.8%;
  }
}

.three-icons {
  margin-left: 16%;
  div.icon-box {
    margin-right: 10.8%;
  }
}

// Link styling
.icon-background:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.icon-box a:hover {
  text-decoration: none;
}
.icon-box a:active {
  text-decoration: none;
}
