// Variables

// Styling for instructor's view of managing doc production tests
.doc-production-instructor-view {
  // Variables
  $doc-production-container-padding: 3em;

  // ---------------------
  // General modifications
  // ---------------------

  h1,
  h2,
  h3 {
    font-weight: 300;
  }

  h3 {
    opacity: 0.6;
  }

  .module-24 {
    padding: 0;
  }

  .doc-production-page-container {
    padding: $doc-production-container-padding;
  }

  // ---------------------
  // Styles for list of doc production
  // ---------------------

  .doc-production-heading {
    margin-bottom: 1em;
  }

  // ---------------------
  // Styles for list of doc production tests
  // ---------------------

  .doc-production-test-list {
    thead {
      color: #fff;
      background-color: $brand-primary;
    }
  }

  // ---------------------
  // Styles for a nested table structure (used by .doc-production-student-assignments and .doc-production-course-builder-list)
  // ---------------------

  // TODO: complete this once student-assignments view is finalized

  // ---------------------
  // Styles for the course builder section
  // ---------------------

  // Alt version of course builder that uses a nested list instead of table to display the data
  // A course week element has a set of options and a collection of course work elements. Each course work elements also has its own set of options. Both course week and course work elements are typically comprised of a heading and a body element  (.heading-container and .body-container respectively). The .body-container is what gets collapsed. The collapsed state is actually defined by the .collapsible, .collapsible-heading, and .collapsible-body
  .doc-production-course-builder-list,
  .doc-production-student-assignments,
  .doc-production-student-assignment-info {
    // Variables

    // course builder heading
    .course-builder-heading {
      margin-bottom: $padding-large-vertical;
      margin-bottom: 1.6em;

      .course-builder-name {
        margin-bottom: 1em;
      }
    }

    &.compact-view {
      .heading-container {
        &.week-heading {
          padding-top: 0.1em;
          padding-bottom: 0.1em;
          color: $brand-primary;
        }

        .assignment-status-icon {
          margin-top: 0;
          margin-bottom: 0;
        }

        .options-container-inline {
          .option-item {
            .value {
              white-space: nowrap;
            }
          }
        }
      }

      .body-container {
        margin-top: 0;
      }

      .course-work {
        padding: 0;
      }
    }

    &.simplified-view {
      .course-week {
        box-shadow: none;
      }
    }

    // div container for the course week. Contains course-week options and many .course-work elements
    .course-week {
      position: relative;
      box-shadow: 0px 1px 5px 0px rgba(#000, 0.3);
      border-radius: 1em;
      margin-bottom: 1.5em;
    }

    // container for course work within a course week. Contains a set of options and settings pertaining to course work
    .course-work {
      padding-top: 1em;

      // Add a subtle background color to a course work element on hover
      &:hover {
        background-color: rgba(#ccc, 0.1);
      }
    }

    // container at the end of both course weeks and course work lists, with a button to create a new row
    .course-weeks-footer {
      margin-bottom: 1.6em;
    }

    // Flash messages
    .flash-messages-container {
      .alert:first-child {
        margin-top: 1em;
      }
    }

    // Placeholder for when there are no weeks present
    .empty-element-placeholder {
      color: rgba(#ccc, 0.4);
      display: flex;
      justify-content: center;
      padding: 1.6em;
    }

    // Container for displaying errors generated from input fields (eg. "Points cannot be empty")
    .form-validation-feedback {
      font-size: $font-size-small;
      opacity: 0.8;
      padding-left: $padding-small-horizontal;
    }

    //
    .course-work-properties-inline {
      padding: 0.5em 0.5em 0.5em 0;

      ul {
        display: flex;
      }

      .text {
        padding: 0.4em;
      }

      .description {
        margin: $padding-large-vertical 0;
        color: rgba(#000, 0.6);

        .text {
          font-weight: 200;
          padding: 0.5em 0.4em 1em;
          display: block;
        }
      }

      li {
        display: flex;
        align-items: center;

        &:nth-child(2) {
          margin-left: auto;
        }

        .text {
          border-radius: 0.5em;
        }

        &:not(.options-expand-collapse):after {
          content: '|';
          display: block;
          padding: 0 0.25em;
          color: rgba(#aaa, 0.7);
          font-size: 0.8em;
        }

        &:hover {
          .text a {
            background-color: rgba(#aaa, 0.1);
          }
        }
      }
    }

    // a more bold view for an input field (eg. the week name input field)
    .emphasized-input-field {
      font-weight: 600;
      color: $brand-primary;
    }

    // container for course work options (modifiable, with inout fields) that are arranged in a multiple columns
    .multi-column-options-container {
      display: flex;
      flex-wrap: wrap;

      .option-item {
        // For a 4 column layout, set `flex: 1 0 25%;`
        flex: 1 0 50%;

        // makes sure a sigle option-item in a row does not span the whole width
        max-width: 50%;
        padding-left: 0.4em;
      }
    }

    // the "horizontal" top section of both course week and course work sections. Typically does not get collapsed
    .heading-container {
      display: flex;
      flex-direction: column;

      // Bootstrap override
      .form-group {
        margin-bottom: 0;
      }

      // specific case for the heading of the course week element. Visually, it has a slight gray background color and more rounded corners, to make it more visually distinctive from other course weeks, but also from the nested course work elements
      &.week-heading {
        padding: 1em;
        background-color: rgba(#ccc, 0.1);
        border-radius: 1em;
      }

      // The main section of the .heading-container that houses the input fields and titles. contains .title-element and .options-container-inline, all on the same row
      .heading-content-row {
        display: flex;
        align-items: center;
      }

      // The hidden section of .heading-container that displays a list of errors specific to all inputs inside .heading-content-row
      .heading-errors-row {
        display: flex;
        justify-content: flex-start;
      }

      // The first main input element (week name or course work type) inside .heading-content-row
      .title-element {
        // Important - dictates how much relative space is allocated to the week title or courswork drop down
        flex: 12 12 0;
      }

      // the right-hand element of .heading-content-row that houses the options/settings (eg. total points for course week)
      .options-container-inline {
        // Important - dictates how much relative space is allocated to the horizontal options in the .heading-content-row. Make sure this number is big enough to fit the number of options, otherwise might overflow
        flex: 18 18 0;
        display: flex;
        justify-content: flex-start;
        min-width: 0;
        opacity: 1;
        transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1) flex;

        // Pushes the options to the right (as is currently the case with week options in the header). Make sure the flex-grow number is big enough to contain the options, otherwise might overflow
        &.right-aligned {
          flex: 4 0 0;
          justify-content: flex-end;

          .option-item {
            flex: 0;

            &.delete-option {
              margin-left: 0;
            }
          }
        }

        &.center-aligned {
          justify-content: center;
        }

        // A container for a single element in the row of options. Typically has a .value and .caption containers inside
        .option-item {
          margin-left: 1.5em;
          flex: 0 0 10em;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          // font-weight: 600;

          // Percent based columns
          &.col-2 {
            flex: 0 0 50%;
          }

          &.col-3 {
            flex: 0 0 33.3%;
          }

          &.col-auto {
            flex: 0 0 auto;
          }

          &.col-type-full {
            flex: 1;

            .value {
              flex: 1;
            }
          }

          &.col-right {
            margin-left: auto;
          }

          // Used when the option needs to be hidden, but the amount of space it takes up retained (eg. used when no course work type is currently selected)
          &.hide-option {
            opacity: 0;
          }

          // special case for displaying total points in week row. The min-width value must be large enough to cover the range of number of digits expected (eg. so 0  points and 1000 points align in the same way across multiple rows)
          .points-field {
            min-width: 5em;
          }

          // the 'x' button at the end of the options row for deleting the current row
          &.delete-option {
            margin-left: auto;
            justify-content: flex-end;
            flex-basis: 0;
            font-weight: 300;
            padding-left: 2em;
            position: relative;
            color: rgba(#000, 0.5);

            &:hover {
              .fa {
                transform-origin: center;
                transform: scale(1.2);
              }

              color: $brand-primary;
            }
          }

          .option-hover-primary {
            &:hover {
              .fa {
                transform-origin: center;
                transform: scale(1.2);
              }

              color: $brand-primary;
            }
          }

          .option-background-hover {
            &:hover {
              background-color: $brand-primary;
              border-color: $brand-primary;
            }
          }

          &.option-align-end {
            margin-left: auto;
            justify-content: flex-end;
          }

          // Nested inside .option-item and is a larger display for the value of the option (eg. in points, it will be the number of points)
          .value {
            flex: 0;
            font-size: 1.2em;
            opacity: 0.8;

            // a modifier class to use a smaller font, useful for displaying values in the course work rows (so they contrast with the week row)
            &.smaller {
              font-size: 1em;
            }

            &.faded {
              opacity: 0.6;
            }

            &.light {
              font-weight: 400;
            }
          }

          // Nested inside .option-item and is a smaller font display of the caption (eg. in points, it will be the word 'points')
          .caption {
            flex: 0;
            font-size: $font-size-small;
            opacity: 0.5;
            padding-left: $padding-small-horizontal;
            padding-right: $padding-small-horizontal;
          }
        }
      }
    }

    // The "vertical" body section of course week and course work elements. Typically collapsed
    .body-container {
      margin-top: 1em;
      padding: 0 1em;

      &.collapsed {
        max-height: 0;
      }
    }

    // container for set of options displayed in a vertical, block (using css lingo) manner (in contrast to .options-container-inline)
    .options-container-block {
      // Add some vertical rhythm to the layout

      label {
        font-size: $font-size-small;
        opacity: 0.8;
        font-weight: 300;
      }

      .options-heading {
        display: flex;
        align-items: center;
        margin-bottom: $padding-large-vertical;

        &:not(.no-line):after {
          content: '';
          display: block;
          flex: 1;
          height: 0px;
          border-bottom: 1px solid rgba(#000, 0.2);
          margin: $padding-small-horizontal;
        }

        padding: 0.5em 0;
      }

      // A container for a single element in the row of options. Typically has a .value and .caption containers inside
      .option-item {
        // Nested inside .option-item and is a smaller font display of the caption (eg. in points, it will be the word 'points')
        .caption {
          margin-bottom: 0;

          // display question mark icon for tooltips
          &:after {
            content: '\f29c';
            font-family: FontAwesome;
            padding: 0 $padding-xs-horizontal;
            opacity: 0.4;
            display: inline;
            visibility: hidden;
          }
        }

        // Nested inside .option-item and is a larger display for the value of the option (eg. in points, it will be the number of points)
        .value {
          background-color: rgba(#ccc, 0.15);
        }

        .textarea {
          resize: vertical;
        }

        // for input values that are constants, this is to coompensate for the padding present inside a default input field
        .constant-value {
          padding-left: $padding-xs-horizontal;
        }

        &:hover {
          .caption {
            &:after {
              visibility: visible;
            }
          }
        }
      }
    }

    // ---------
    // Course Builder Helper classes
    // ---------

    // remove border form input field, but make it appear again on hover
    .borderless-input:not(.show-border) {
      &:not(:hover) {
        transition: 0.5s all;
        border-color: transparent;
        box-shadow: none;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }

    // same as .borderless-input, but for select fields and still with the input appearance
    .borderless-select {
      &:not(:hover) {
        transition: 0.5s all;
        border-color: transparent;
        box-shadow: none;
        background-color: rgba(#ccc, 0.1);
      }
    }

    // like .borderless-input, but maintaining the bottom line
    .underlined-input:not(.show-border) {
      &:not(:hover) {
        transition: 0.5s all;
        border-color: transparent;
        box-shadow: none;
        border-bottom-color: $input-border;
      }
    }

    // Styling for the file icon (eg. the .docx file)
    .file-icon {
      &:hover {
        color: $brand-primary;
        transform-origin: center;
        transform: scale(1.2);
      }
    }

    // The (+) / (-) button for expanding/collapsing sections.
    .collapse-expand-button {
      padding-right: $padding-small-horizontal;
      color: rgba(#000, 0.2);
      transition: 0.15s ease-in-out all;

      // Hide  the expand/collapse button
      &.disabled {
        &:before {
          opacity: 0;
        }
      }

      // Does not actually show the expand/collapse button, but renders so it takes up space. Used for properly aligning row elements
      &.placeholder {
        visibility: hidden;
        max-height: 0;
      }

      &:not(.placeholder):hover {
        transform-origin: center;
        transform: scale(1.2);
        opacity: 0.7;
        color: $brand-primary;
      }

      // The actual expand/collapse button, default collapsed state
      &:before {
        content: '(+)';
        font-family: 'Roboto Mono', monospace;
        font-size: 0.9em;
        display: block;
      }

      // expand/collapse button, expanded state
      &.expanded {
        &:before {
          content: '(-)';
        }
      }
    }

    // Define a section that is collapsible. When present, a child element with .collapse-heading class stays, while the .collapse-body element gets collapsed/expanded
    .collapsible {
      // defines the element that remains when the .collapsible section is to be collapsed
      .collapse-heading {
      }

      // defines the element that is collapsed when the .collapsible section is to be collapsed
      .collapse-body {
        opacity: 1;
        transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1) transform, 0.25s cubic-bezier(0.4, 0, 0.2, 1) opacity;
        max-height: none;
        overflow: visible;
        transform: translateY(0em);
      }

      // The collapsed state of .collapsible element, minimizing the height of.collapse-body
      &.collapsed {
        .collapse-heading {
        }

        .collapse-body {
          max-height: 0;
          opacity: 0;
          transform: translateY(-2em);
          overflow: hidden;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  // ---------------------
  // Styles for the student assignments view
  // ---------------------
  .doc-production-assignments-priority {
    margin-bottom: 1.6em;

    .heading-container {
      &.priority-heading {
        display: flex;
        margin-bottom: 0.5em;

        // justify-content: center;
        flex-direction: column;

        .priority-heading-caption {
          display: flex;
          justify-content: center;
          color: rgba($gray, 0.5);
        }

        .expandable-heading-container {
          display: flex;
          justify-content: center;
        }

        .heading-content-row {
          display: flex;
          justify-content: center;
          border: 2px solid $brand-success;
          border-radius: 1em;
          flex-basis: 50%;
          flex-grow: 1;
          flex-shrink: 0;
          transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1) flex-grow;
          padding: 0.5em;
        }

        .title-element {
          flex: 0;
          white-space: nowrap;

          .emphasized-input-field {
            color: $brand-success;
          }
        }
      }
    }

    .notification-counter-container {
      border-radius: 100%;
      justify-content: center;
      background-color: $brand-success;
      margin: 0.5em;
      width: 1.5em;
      height: 1.5em;
      position: relative;

      .notification-counter-value {
        width: 100%;
        height: 100%;
        display: flex;
        left: 0;
        top: 0;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: 600;
      }
    }

    &.collapsed {
      .heading-container .heading-content-row {
        flex-grow: 0;
      }
    }

    .empty-element-placeholder {
      display: none;
    }

    &.empty {
      .empty-element-placeholder {
        display: flex;
      }

      .course-work-collection {
        display: none;
      }

      .heading-container {
        opacity: 0.2;

        &.priority-heading {
          .heading-content-row {
            border-color: $gray;
          }

          .title-element .emphasized-input-field {
            color: $gray;
          }
        }

        .notification-counter-container {
          display: none;
        }

        color: $gray;
      }
    }
  }

  // ---------------------
  // Styles for the student assignments view
  // ---------------------
  .doc-production-student-assignment-info {
    // .assignment-info-heading {
    //   .title, .subtitle {
    //     margin: 2em 0;
    //     font-weight: 200;
    //     justify-content: center;
    //     align-items: center;
    //     text-align: center;
    //   }

    //   .subtitle{
    //     margin: 0;
    //     padding: 0;
    //     opacity: 0.7;
    //   }
    // }

    .past-submissions-container {
      // padding: 0 $doc-production-container-padding;

      // .course-work .heading-container{
      //   .options-container-inline{
      //     .opttion-item{
      //       flex: 0 0 50%;
      //     }
      //   }
      // }

      .section-title,
      .section-subtitle {
        display: flex;
        text-align: center;
        justify-content: center;
      }

      .section-title {
      }

      .section-subtitle {
        opacity: 0.7;
      }

      .section-list {
        margin-bottom: 1em;
      }

      .option-item {
        white-space: nowrap;

        // flex-direction: column;
        // flex: 0 0 50%;
        flex-wrap: wrap;

        .caption {
          flex-basis: 50%;
        }

        .value {
          flex-basis: 50%;
        }
      }
    }
  }

  // ---------------------
  // Styles for the student assignments view
  // ---------------------
  .doc-production-student-assignments {
    // .heading-container {
    //   &.week-heading {
    //     padding-top: 0.1em;
    //     padding-bottom: 0.1em;
    //     color: $brand-primary;
    //   }

    //   .assignment-status-icon {
    //     margin-top: 0;
    //     margin-bottom: 0;
    //   }

    //   .options-container-inline {
    //     .option-item {
    //       .value {
    //         white-space: nowrap;
    //       }
    //     }
    //   }
    // }

    // .body-container {
    //   margin-top: 0;
    // }

    // .course-work {
    //   padding: 0;
    // }

    // Different course work states
    .course-work.complete,
    .course-week.complete {
      .title-element,
      .options-container-inline,
      .btn-link {
        text-decoration: line-through;
      }

      .assignment-status-icon {
        transform: scale(1);
        opacity: 0.3;

        // color: $brand-primary;
        &:after {
          content: '\f00c';
          font-family: FontAwesome;
        }
      }
    }

    .course-work.disabled,
    .course-week.disabled {
      opacity: 0.3;

      .assignment-status-icon {
        transform: scale(0.8);
        opacity: 0.8;

        &:after {
          color: $gray;
          content: '\f05e';
          font-family: FontAwesome;
        }
      }
    }
  }

  // TODO: rename this appropriately
  .emphasized-input-field {
    font-size: $font-size-large;
    color: inherit;
    padding: 0;
  }

  // Helper classes
  .assignment-status-icon {
    margin: 0.5em;
    transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1) transform, 0.15s linear opacity, 0.15s linear color;
    transform: scale(0.4);
    opacity: 0.3;
    display: block;

    // color: $brand-primary;

    &.larger {
      font-size: 1.2em;
    }

    &:after {
      content: '\f1db';
      font-family: FontAwesome;
    }
  }

  // ---------
  // Helper classes
  // ---------

  // Full width container that takes up entire browser width, so that
  .full-width-responsive-container {
    position: relative;
    width: 100vw;
    max-width: $root-container-width;
    display: flex;
    flex-direction: column;

    .responsive-row {
      display: flex;

      .responsive-row-content {
        flex: 0 1 $root-container-width;
        min-width: 0;
        padding: 3em;
      }
    }
  }

  // TODO: Clean this up and probably move to a shared css file
  .add-date-time-picker {
    // TODO: this is a quick hack fix. Clean it up
    .input-group {
      position: relative;
    }

    .hidden-date-time-picker-input {
      position: absolute;
      bottom: 0;
      left: 0;
      visibility: hidden;
    }
  }

  // Styling for a stylized animated keyboard key. Used by press to continue component and text sequence component
  // Note: This class is duplicated in the doc_production_instructions.scss file
  .keyboard-key {
    font-size: 1em;
    margin: 0 0.3em;
    border: 3px solid $gray-lighter;
    border-radius: 0.2em;
    box-shadow: 0 3px 10px rgba($gray-light, 0.4);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 1.5em;
    height: 1.5em;
    color: $gray-light;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transform: scale(0.99);
    transition: color 0.15s, box-shadow 0.15s, border 0.15s;
    font-weight: 300;
    padding: 0.7em;
    background-color: #fff;

    &.display-only {
      box-shadow: none;
      border-color: $brand-success;
      color: $brand-success;
    }

    &:not(.display-only):hover {
      box-shadow: 0 0 2px rgba($gray-light, 0.6);
      transform: scale(0.95);
      color: rgba($gray-light, 0.8);
      border: 3px solid rgba($gray-lighter, 0.5);
    }
  }

  // Symbols (and a hover tooltip) for rendering symbols (eg. the paragraph formatting symbol in docx files)
  .formatting-symbol {
    &.paragraph {
      position: relative;

      &:after {
        content: '\00B6';
        display: inline;
      }

      &:hover {
        &:before {
          content: 'Hard return';
          position: absolute;
          background-color: #000;
          color: #eee;
          border-radius: 0.5em 0.5em 0.5em 0;
          top: -100%;
          left: 100%;
          white-space: pre;
          padding: 0 0.5em;
          margin-left: 0.5em;
          margin-bottom: 0.5em;
        }
      }
    }
  }
}

// For use globally
.star-container {
  display: flex;
  flex-shrink: 1;
  padding: 0;
  // padding: 0 0.2em;
  align-items: center;

  &.smaller {
    font-size: 0.8em;
  }

  &.inline {
    display: inline-flex;
  }

  .star {
    margin: 0 0.2em;
    font-size: 1.2em;

    &:after {
      content: '\f006';
      font-family: FontAwesome;
      display: block;
    }

    transition: 0.15s transform;

    &:hover {
      transform: scale(1.2);
    }
  }

  &.bronze-selected,
  &.bronze-hover {
    .bronze-star:after {
      content: '\f005';
    }

    .silver-star:after {
      content: '\f006';
    }

    .gold-star:after {
      content: '\f006';
    }
  }

  &.silver-selected,
  &.silver-hover {
    .bronze-star:after {
      content: '\f005';
    }

    .silver-star:after {
      content: '\f005';
    }

    .gold-star:after {
      content: '\f006';
    }
  }

  &.gold-selected,
  &.gold-hover {
    .bronze-star:after {
      content: '\f005';
    }

    .silver-star:after {
      content: '\f005';
    }

    .gold-star:after {
      content: '\f005';
    }
  }

  .bronze-star {
    color: #cd7f32;
  }

  .silver-star {
    color: #c0c0c0;
  }

  .gold-star {
    color: #ffd700;
  }
}

@keyframes fade-out-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
