#leaderboard-div {
  padding-bottom: 18px;

  span.name {
    font-size: 16px;
  }
  span.xp {
    color: #999;
    font-size: 14px;
  }

  span.number {
    // color: #999;
    font-size: 14px;
  }

  p {
    color: #999;
    font-size: 16px;
    margin-top: -6px;
  }
}
