#exercises-div {
  .extra-margin-top {
    margin-top: 11px;
  }

  #progress-bar-subtitle {
    font-size: 16px;
    color: #999;
    margin-top: -20px;
  }

  #hr-above {
    margin: 16px 0px;
  }
  #hr-below {
    margin: 10px 0px 22px 0px;
  }

  // Score Board
  #score-board {
    h5 {
      color: #999;
    }

    .score {
      font-size: 24px;
    }
  }

  #keyset {
    h5 {
      color: #999;
      margin: 2px 0px;
    }
    span {
      padding: 0px 3px;
      display: inline-block;
      height: 16px;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    span.active {
      border-bottom: 3px solid green;
    }
  }

  hr#score-board-split {
    margin-top: 10px;
    // margin-bottom:
  }
}
