/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 */

// "bootstrap-sprockets" must be imported before "bootstrap" and "bootstrap/variables"
@import 'components/bootstrap-4-migration'; // variables and classes that no longer exist in bootstrap 4
@import 'components/bootstrap-5-migration'; // variables and classes that no longer exist in bootstrap 4


@import 'components/bootstrap-overrides';
@import 'components/icons';
@import 'components/badges';
@import 'components/leaderboard';
@import 'components/daily_activity';
@import 'components/exercises';
@import 'components/keyboard';
@import 'components/radar';
@import 'components/hands';
@import 'components/profile';
@import 'components/welcome';
@import 'components/championship-mode';
// From gemfile:
// // All bootstrap overrides go ABOVE
@import 'bootstrap';
// // Non-overrides go BELOW
@import 'components/class';
@import 'components/homerowtech';
@import 'components/timed-writing';
@import 'components/loading-keyboard';
@import 'components/tooltip';
@import 'components/nav';
@import 'components/typing-arena';
@import 'components/error-success-messages';
@import 'components/challenge';
@import 'components/lesson';
@import 'components/level';
@import 'components/home';
@import 'components/footer';
@import 'components/games';
@import 'components/transcription';
@import 'components/accommodation-page';
@import 'components/tutorials-page';
@import 'components/admin_dashboard';
@import 'components/practice-text';
@import 'components/finger-animated-keyboard';
@import 'components/student-dashboard';
@import 'components/technique-hub';
@import 'components/course_template_table';
@import 'components/test_centre';
@import 'components/certificates';
@import 'components/violated-permissions-instructor-view';
@import 'components/doc_production';
@import 'components/doc_production_results';
@import 'components/doc_production_instructions';
@import 'components/typist-blockquote';
@import 'components/cards';
@import 'components/stripe_form';


@font-face {
  font-family: 'OpenDyslexic';
  src: url('fonts/OpenDyslexic-Regular.otf');
}

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.0/font/bootstrap-icons.css");

// Disabling ligatures for roboto. An issue arises with the "fi" letter combination.
// For example, in the word "Profile" the "i" has no dot above it.
* {
  font-variant-ligatures: no-common-ligatures;
}

body {
  // font-family: "OpenDyslexic";
  // padding-bottom: 70px;

  // Prevent horizontal scrolling when using 100vw in test center component
  overflow-x: scroll;

  // Fixes an issue on Chrome on Mac
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Note: This is primarily leveraged in our print certificate feature
  @media print {
    background-color: transparent;
  }

  // We used to set this by overriding $body-bg, but as of Bootstrap 5.3, that variable
  // is being used as a default background for many other things (such as cards, etc.) so 
  // it's best to set the color this way
  background-color: #e9e9e9;
}


/* Extending Bootstrap 4 with additional modifier classes */
.bg-typist-blue {
  background: $typist-blue;
}

// Slightly darker version of typist blue, such that it passes WCAG AA success criteria
// of 3:1 contrast ratio for large white text in the foreground (i.e. navbar)
.bg-typist-blue-darker {
  background: darken($typist-blue, 5%);
}

.typist-blue {
  color: $typist-blue;
}

// Module styling
.typist-pane {
  @extend .bg-white;
  @extend .rounded;
  padding: $spacer * 1.5;
  // Make bottom padding a bit smaller because content inside panels often already has
  padding-bottom: 0.4em; //variable from Bootstrap 4, equivalent to p-3

  // background-color: #fff;
  // border-radius: 10/16 * 1rem;
  // padding: (10/16 * 1rem) (50/16 * 1rem) (10/16 * 1rem) (50/16 * 1rem);
  // margin-bottom: 40/16rem;
}

.success-underline {
  background-image: linear-gradient(120deg, $success 0%, $success 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2rem;
  background-position: 0 100%;
  transition: background-size 0.25s ease-in;

  // &:hover {
  //   background-size: 100% 0.3rem;
  // }
}

// Custom code input field (eg. joining class or games)
.typist-section-code-input {
  text-transform: uppercase;
  text-align: center;
  font-weight: $font-weight-bold;
  font-size: 33px;
  letter-spacing: 3px;
  color: #888;
  width: 160px;
  height: 1.3em;
  padding: 0;
}

// This helper class is meant for augmenting a
// Bootstrap 4 .container with a min-width property
// corresponding to the extra large container in Bootstap 4
.container.container-min-width {
  min-width: 940px;
  min-width: 1140px;
}

// Add additional background colors
// NOTE: adding !important at the end of each style (this is how BS5 also does it for it's background class helpers)
// so that adding this helper will override any other css specificity (eg. in striped table, Bootstrap targets every other row, and that specificity is higher
// than that of a helper class, so `!important` is necessary)

.bg-warning-light {
  // Same color from previous version of Typist (the .bg-warning of Bootstrap 3)
  background-color: #fcf8e3;//!important;
}

.bg-success-light {
  // Same color from previous version of Typist (the .bg-success of Bootstrap 3)
  background-color: #dff0d8 !important;
}

.bg-info-light {
  // Same color from previous version of Typist (the .bg-info of Bootstrap 3)
  background-color: #d9edf7 !important;
}

.bg-danger-light {
  // Same color from previous version of Typist (the .bg-danger of Bootstrap 3)
  background-color: #f2dede;//!important;
}

// Hover effect to turn the background to Bootstrap light color
.hover-bg-light {
  &:hover {
    background-color: $light !important;
  }
}

// Adding a helper class for close button
.btn-close {
  // padding override fixes a weird 'active' attribute
  //  that happens when you click the X
  padding: 1rem 1rem 0.6rem 1rem;
}

// Fix for print layouts. Bootstrap 4.4.1 sets the page size to A3, which removes the option
// to pick page size in print dialogue and affects the sizing of the print layout
// Fix from: https://stackoverflow.com/questions/49785039/printing-bootstrap-4-html-page-in-chrome-cuts-off-chrome-header-footers/50400421#answer-50400421
// Note, the print options in Bootstrap cause issues and are in fact being removed in upcoming versions:
// https://github.com/twbs/bootstrap/pull/28753
// https://github.com/twbs/bootstrap/pull/28339
@page {
  size: auto;
}

// Custom hover and active behaviour navlinks in navbar
// ui-sref-active sets active state on parent of the link,
// But we need to set it on the child .nav-link element
.nav-item-custom {
  &.active .nav-link {
    background: #00000014;
  }

  .nav-link {
    // Override the dim white color on the default .nav-link
    color: white !important;
    font-size: 1.2rem; // 19px (if bold, qualifies as large text by WCAG). Requires 3:1 contrast ratio, instead of 4.5:1.
    font-weight: 700; // bold to meet AA accessibility definition for large text

    // Override default .nav-link hover behaviour
    &:hover {
      background: #00000014;
      text-decoration: none;
    }
  }
}

// Custom helper for max and min width for modals
.modal-md-custom {
  // Setting both min and max in order to overwrite bootstrap defaults
  min-width: 720px;
  max-width: 720px;
}

// Adds a 1px dark outline to the text it is applied to
.dark-font-outline{
  // https://css-tricks.com/adding-stroke-to-web-text/
      // For some reason, webkit-text-stroke stopped working properly for Roboto
      // NOTE: this text shadow approach does not work for pixel widths < 1px
      text-shadow:-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}