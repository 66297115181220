table.course-template-table {
  .star-cell {
    color: #ccc;
  }
  .lesson-star-large {
    margin: 2px 4px 0px 4px;
    font-size: 28px; // animations
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
  }
  // .lesson-star-small {
  //     margin-top: 6px;
  //     color: #ccc;
  // }
}
