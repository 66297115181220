.square-chart-xs-18 {
  height: 630px;
  .chart-container {
    height: 100%;
    width: 100%;
  }
}
.square-chart-xs-16 {
  height: 560px;
  .chart-container {
    height: 100%;
    width: 100%;
  }
}

.square-chart-xs-12 {
  height: 420px;
  .chart-container {
    height: 100%;
    width: 100%;
  }
}
