@mixin rotate($deg: 90) {
  $sDeg: #{$deg}deg;

  -webkit-transform: rotate($sDeg);
  -moz-transform: rotate($sDeg);
  -ms-transform: rotate($sDeg);
  -o-transform: rotate($sDeg);
  transform: rotate($sDeg);
}

#level {
  .checkmark {
    i {
      line-height: 70px;
    }
  }

  .star-row-vertical {
    position: absolute;
    i {
      position: absolute;
      font-size: 22px;
    }
    .bronze {
      @include rotate(345); // 25
      left: -20px;
      top: 52px;
    }
    .silver {
      @include rotate(-6);
      left: -30px;
      top: 18px;

      font-size: 27px;
    }
    .gold {
      @include rotate(25);
      left: -18px;
      top: -18px;
      font-size: 34px;
    }
  }
}
