// attached to body element
.transcription-print {
  @media print {
    padding-bottom: 0px;
    @page {
      // Note: Test this in Incognito mode since if the page orientation is manually set in the browser
      // then it will use the last user setting instead of this CSS property.
      size: landscape;
    }

    // add the .hide-on-print class to any specific elements
    .hide-on-print {
      display: none;
    }
    nav {
      display: none;
    }
    header {
      display: none;
    } // This covers the new navbar
    footer {
      display: none;
    }
    .module-24 {
      margin-bottom: 0px;
    }
    main {
      margin-top: 0px;

      .container {
        margin-top: 0px;
        // These changes essentially convert the .container to a .fluid-container under print mode
        width: 100%;
        min-width: unset;
        max-width: unset;
      }
    }
  }
}

#transcription {
  .logo-transcription-image {
    height: 90px;
    margin-top: 6px;
  }

  .module-24 {
    // remove rounded edges
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;

    // add a border
    border: 1px solid #bbb;
  }

  #start-timer-on-keystroke {
    left: 0;
    position: absolute;
    width: 100%;
    font-size: 18px;
    color: #999;
    letter-spacing: 1px;
    font-weight: $font-weight-bold;
  }

  // use these two classes to create a fade effect
  .opacity-transition {
    -webkit-transition: opacity linear 800ms; /* Safari */
    transition: opacity linear 800ms;
  }
  .opacity-0 {
    opacity: 0;
  }
  .btn-transition {
    -webkit-transition: all linear 400ms; /* Safari */
    transition: all linear 400ms;
  }

  #finish-button {
    position: fixed;
    z-index: 100;
    top: 80px;
    right: 20px;

    button {
      font-size: 18px;
      white-space: normal; // this wraps the button text
      width: 160px;
      height: 60px;
      opacity: 0.8;
    }
  }

  #typing-area {
    line-height: 32px;
    height: 1100px;

    font-size: 20px;

    &:focus,
    &:focus {
      outline: none;
    }
    border-width: 0px;
    border: none;

    width: 100%;
  }
  // necessary for firefox
  #typing-area:disabled {
    background-color: white;
  }

  .transcription-modal-image {
    width: 100%;
  }
}
