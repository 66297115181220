#profile-statistics {
  .table-borderless {
    margin-bottom: 0px;
  }

  .table-borderless tbody tr td,
  .table-borderless tbody tr th,
  .table-borderless thead tr th {
    border: none;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-top: 3px;
    // padding-right: 0px;
  }
  p {
    margin-bottom: 0px;
  }
  .panel {
    margin-bottom: 12px;
  }

  .panel-body {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
  }
}
