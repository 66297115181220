#home {
  .checkmark {
    i {
      line-height: 50px;
    }
  }
  .hands-over-keyboard-zoomed {
    img {
      width: 480px;
      height: auto;
    }
  }
}
