// ---------------------
// Styles for student instructions section
// ---------------------
.doc-production-student-instructions {
  // Variables
  $student-instructions-checkbox-width: 1.5em;
  $student-instructions-checkbox-margin: 0.5em;

  .doc-production-timer-container {
    display: flex;

    // justify-content: center;
    // align-items: center;
    width: 100%;
    font-size: 1.5em;

    .timer-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      color: $gray-dark;

      .timer {
        display: flex;
        flex-direction: column;
        align-items: center;

        .panel-view {
          border-bottom: 1px solid rgba(#000, 0.25);
          border-radius: 0.2em;
          box-shadow: 0 6px 10px 0 rgba(#666, 0.2);
          padding: 0 0.5em;

          // background-color: #fff;
          text-align: center;
        }

        .digits {
          font-size: 1.6em;

          // border-bottom: 1px solid rgba(#000, 0.25);
          // border-radius: 0.2em;
          // box-shadow: 0 6px 10px 0 rgba(#666, 0.2);
        }

        .caption {
          // font-size: 0.8em;
          max-height: 5em;
          overflow: hidden;
        }

        .message {
          display: flex;
          flex-direction: column;
          align-items: center;

          // border: 1px solid rgba($brand-primary, 1);
          // border-radius: 1em;
          text-align: center;

          // background-color: #fff;
          // box-shadow: 0 0.1em 0.2em 0 rgba(#000, 0.1);
          // padding: 0.5em;
          .title {
            font-size: 1.6em;
          }

          .subtitle {
            font-size: 0.7em;
            color: $brand-primary;
          }
        }
      }
    }

    .timer-wrapper.fixed {
      position: fixed;
      top: $navbar-height;
      left: 0;
      z-index: 99999;

      .timer {
        padding: 0.5em;
        border-radius: 0 0 1em 1em;
        background-color: rgba(#fff, 0.8);
      }

      .caption {
        max-height: 0;
      }
    }

    .timer-wrapper.hidden-placeholder {
      max-width: 0;
      overflow: hidden;
      visibility: hidden;
      white-space: nowrap;
    }
  }

  .empty-element-placeholder {
    color: rgba(#ccc, 0.4);
    display: flex;
    justify-content: center;
    padding: 1.6em;
  }

  // A container for a set of sub-instructions. Has many .instruction elements. If no sub-instructions needed, all instructions can be stored in a single .instructions-container
  .instructions-container {
    margin-bottom: 2em;

    // Adds extra padding and a rounded grey rectangle to the background, to help distinguish it from subsequent .instruction-container elements
    &.panel {
      padding: 2em;
      background-color: rgba(#ccc, 0.1);
      border-radius: 1em;
      border: 1px solid rgba(#000, 0.15);
    }
  }

  // An instruction inside .instructions-container. Each .instruction is made up of multiple .instruction-row (a sub-section of an instruction, eg. a paragraph of text would be one row, and a text prompt to be copied by the student would be another row)
  .instruction {

    // Overriding the sizing for headings displayed in instructions
    &.markdown-instruction{
      h1, h2, h3, h4, h5, h6{
        font-weight: bolder;
      }
      h1 {
        font-size: $h2-font-size;
      }
      h2 {
        font-size: $h3-font-size;
      }
      h3 {
        font-size: $h4-font-size;
      }
      h4 {
        font-size: $h5-font-size;
      }
      h5 {
        font-size: $h6-font-size;
      }
    }

    .extra-bold-strong-tags strong {
        font-weight: 800;
    }

    //   CHANGE: Removed the Flex attribute
    display: flex;

    // align-items: flex-start;
    align-items: stretch;
    transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1) all;
    transform-origin: left;
    flex-direction: column;

    margin-bottom: 2em;

    &:hover {
      background-color: rgba(#ccc, 0.1);
    }

    &.checked {
      opacity: 0.5;

      .instruction-content {
        text-decoration: line-through;
      }
    }

    // specific formatting for images that are instructions
    .instruction-image {
      // TODO: Prevent image from being copied to local computer
      opacity: 0.76;
      padding: 1em;
    }

    .image-label {
      font-family: 'Roboto Mono', monospace;
      font-size: 20px;
    }

    // A sub-element within a single instruction (eg. one row can be a paragraph of text, while the next row is a full block of text to be copied by the student).
    // instruction-row elements that take up the entire horizontal space (ignoring the margin created by checkbox) should have the .full-width class (this is required for the .docx-input.block element)
    .instruction-row {
      flex: 1;
      padding-left: 0.5em;
      line-height: 1.5em;
      display: flex;
      align-items: flex-start;

      // Add a placeholder margin to .instruction-row items that are 2nd row +. Margin equals amount checkbox takes up
      &:not(:first-child) {
        margin-left: 2.5em;
        margin-left: $student-instructions-checkbox-margin * 2 + $student-instructions-checkbox-width;
      }

      // Add this class to an element that takes up the entire width of container and has ability to expand full screen (i.e. .docx-input)
      &.full-width {
        flex: 1 1 100%;
        margin-left: 1.5em;

        // .instruction-content{
        //   flex: 1;
        // }
        // This class causes the .full-width container to expand to full width of browser
        &.expanded {
          position: relative;
          width: 100vw;
          right: 50%;
          left: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
          z-index: 999;
          padding: 0;

          // A faded black background that serves as a backdrop for the expanded .instruction-row element
          &:after {
            position: fixed;
            display: block;
            content: '';
            width: 100vw;
            height: 100vh;
            background-color: black;
            left: 0;
            top: 0;
            z-index: -1;
            opacity: 0.2;
          }

          .instruction-content {
            margin: 0;
            padding: 0;
            animation: 0.5s cubic-bezier(0.4, 0, 0.2, 1) fade-out-in;
          }

          .docx-input {
            max-height: none;

            &.block {
              &:before {
                display: none;
              }

              &:after {
                display: none;
              }
            }
          }
        }
      }
    }

    .instruction-number {
      font-weight: 600;
      font-size: 1.5em;
      min-width: 1.5em;
    }

    // The other element that can be found inside .instruction-row, though only on the first element.
    .instruction-checkbox {
      display: flex;
      overflow: hidden;
      position: relative;
      justify-content: stretch;
      align-items: stretch;
      padding: 0.2em 0.5em;
      top: 0px;

      .order-number {
        text-align: center;
        font-size: 1.2em;
        font-weight: 600;

        &:before {
          content: '111';
          display: block;
          max-height: 0;
          visibility: hidden;
          margin: 0;
          padding: 0;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .checkbox-container {
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        // visibility: hidden;

        z-index: 10;
      }

      .checkbox {
        width: $student-instructions-checkbox-width;
        height: $student-instructions-checkbox-width;
        margin: $student-instructions-checkbox-margin;
        flex: 0 0 $student-instructions-checkbox-width;
        border-radius: 0.2em;
        transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1) all;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(#fff, 0.2);

        // border-bottom: 1px dashed rgba(#000, 0.3);
        box-shadow: 0 3px 4px -3px #00000059;
      }

      &.checked {
        .checkbox {
          border: 1px solid rgba(#000, 0.3);
          background-color: rgba(#000, 0.3);
          opacity: 0;

          &:after {
            content: '\f00c';
            font-family: FontAwesome;
            color: #fff;
            text-decoration: none;
          }
        }
      }

      &:hover {
        // background-color: #fff;
        transform: scale(1.1);

        &.checked .checkbox {
          background-color: rgba(#000, 0.2);
          opacity: 1;
        }

        .checkbox {
          border: 1px solid rgba(#000, 0.3);
        }

        .checkbox-container {
          visibility: visible;
        }
      }

      &:active .checkbox {
        background-color: rgba(#000, 0.4);
        transform: scale(0.9);
      }
    }

    // inner container inside .instruction-row (to differentiate against .instruction-checkbox element)
    .instruction-content {
      margin-left: 1em;
      text-decoration: inherit;
      flex: 1;
    }

    .docx-download-icon {
      display: flex;

      // flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      // font-size: 0.9em;
      padding: 1.5em;
      color: $brand-primary;

      &:before {
        content: '\f1c2';
        font-family: FontAwesome;
        font-size: 3.5em;
        display: block;
        margin: 0.2em;
        transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1) transform;
      }

      &:hover {
        &:before {
          transform: scale(1.1);
        }
      }
    }

    .instruction-content.disabled-docx-download-icon {
      opacity: 0.6;

      a {
        pointer-events: none;
        cursor: default;
        text-decoration: none;
      }

      .docx-download-icon {
        color: $gray;

        &:hover {
          &:before {
            transform: none;
          }
        }
      }
    }

    // A class that can go inside .instruction-content and is for displaying inline text to be copied by the student into a docx file. To display a bigger section of text, add the .block class
    .docx-input {
      text-decoration: inherit;
      border: 1px solid rgba(#000, 0.2);
      background-color: rgba(#aaa, 0.1);
      border-radius: 0.5em;
      font-family: 'Roboto Mono', monospace;
      position: relative;
      max-height: 40vh;
      overflow: hidden;
      white-space: pre;
      padding: 0.3em 0.7em;
      margin: 0;
      box-shadow: 0px 2px 8px 0px #0003;
      background-color: #fff;
      border: none;
      transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1) box-shadow;

      // this code prevents a student from copying-pasting text
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;

      &.block {
        padding: 0.7em;
        white-space: pre-wrap;
        display: block;
        margin: 1em;

        // A whit faded gradient over the non-expanded .docx-input element
        &:before {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 999;
          background: linear-gradient(to bottom, rgba(#fff, 0), rgba(#fff, 1));
        }

        // Text that appears as an overlay on top of the non-expanded .docx-input element
        &:after {
          content: 'Click to see full prompt';
          font-family: 'Khula', sans-serif;
          display: flex;
          color: rgba(#000, 0.1);
          font-size: 1.3em;
          font-weight: 600;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: 0.15s cubic-bezier(0.4, 0, 0.2, 1) all;
          opacity: 0;
          color: $gray-darker;
          background-color: rgba(#fff, 0.7);
          opacity: 1;
        }

        &:hover {
          box-shadow: 0px 6px 12px 0px #0003;

          &:after {
            // color: $brand-primary;
            // background-color: rgba(#fff, 0.7);
            // opacity: 1;
            color: $brand-primary;
          }
        }
      }
    }
  }

  // Styling for a stylized animated keyboard key. Used by press to continue component and text sequence component
  .keyboard-key {
    font-size: 1em;
    margin: 0 0.3em;
    border: 3px solid $gray-lighter;
    border-radius: 0.2em;
    box-shadow: 0 3px 10px rgba($gray-light, 0.4);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 1.5em;
    height: 1.5em;
    color: $gray-light;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transform: scale(0.99);
    transition: color 0.15s, box-shadow 0.15s, border 0.15s;
    font-weight: 300;
    padding: 0.7em;
    background-color: #fff;

    &.display-only {
      box-shadow: none;
      border-color: $brand-success;
      color: $brand-success;
    }

    &:not(.display-only):hover {
      box-shadow: 0 0 2px rgba($gray-light, 0.6);
      transform: scale(0.95);
      color: rgba($gray-light, 0.8);
      border: 3px solid rgba($gray-lighter, 0.5);
    }
  }

  // Styling for the corporate contacts directory
  .directory-listing {
    padding: 1em;
    background: #eee;
    line-height: 1.6;

    .search-bar {
      line-height: 1;
      margin-bottom: 0.6em;
    }

    .pagination-container {
      display: flex;
      justify-content: center;

      .pagination {
        margin-top: 0;
      }
    }

    .empty-placeholder {
      padding: 1em;
      opacity: 0.5;
      position: relative;
    }
  }

  // Style overrides for the compact version of the contact card
  .contact-card-wrapper.compact {
    .contact-card {
      padding: 0;
      margin: 0.2em 0;

      .contact-name {
        font-size: 1em;
      }

      .contact-card-body {
        display: none;
      }

      .contact-details {
        flex: 0 0 auto;
        margin: 0;

        li:before {
          content: '|';
          display: inline;
          padding: 0 0.3em;
        }
      }
    }
  }

  // Expanded contact card styling
  .contact-card {
    // Change these variables (they refer to em units) to change the size of the avatar circle with initials
    $contact-card-avatar-size: 1.8;
    $contact-card-avatar-padding: 0.8;

    padding: 0.6em;
    margin: 0.6em 0;
    box-shadow: 0 6px 10px 0 rgba(#666, 0.2);
    background: #fff;

    // Heading section of the contact card comprised of .avatar-image-container, .contact-name and .contact-details (contains the company name)
    .contact-card-heading {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .avatar-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex: 0 0 auto;
        padding: 0 ($contact-card-avatar-padding * 1em);
      }

      .avatar-image {
        width: $contact-card-avatar-size * 1em;
        height: $contact-card-avatar-size * 1em;
        background: #2da8e3;
        border-radius: 50%;
        position: relative;

        & .initials {
          position: absolute;
          display: flex;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: calc($contact-card-avatar-size/2 * 1em);
        }
      }

      .contact-name {
        flex: 0 1 auto;
        margin: 0.5em 0;
        font-weight: 400;
        font-size: 1.2em;
      }

      .contact-details {
        flex: 1 1 100%;
        padding: 0;
        margin: 0;
        line-height: 1;
        margin-left: ($contact-card-avatar-size + ($contact-card-avatar-padding * 2)) * 1em;
        margin-top: -0.5em;
        // opacity: 0.6;
        font-weight: 300;
      }
    }

    // Main section of the contact card, contains lists of properties. This is hidden in the compact view
    .contact-card-body {
      padding: 0 1em;

      .detail-row {
        line-height: 1.3;
        margin-bottom: 1em;
      }

      .detail-name {
        opacity: 0.4;
        font-size: 0.85em;
      }

      .detail-value {
        font-weight: 300;
        padding-left: 0.6em;
      }
    }
  }

  //  Styling the starter file browser instruction widget
  .starter-file-browser {
    // The display area that lists the docx files and folders
    .file-display-area {
      overflow-y: scroll;
      height: 15rem;
      max-height: 15rem;
    }

    // Helper class for the inactive state of back button
    .inactive-back-button {
      opacity: 0.2;
    }

    // refering to both folders and docx as files
    .file {
      // Add a slight blue background on hover like in windows explorer
      &:hover {
        background-color: #cce8ff;
      }

      // A helper class for changing the color of folder icon to yello
      .fa-folder-yellow {
        color: #feca29;
      }

      // A helper class for changing the color of the docx icon
      .fa-file-word-o-blue {
        color: #295396;
      }
    }
  }
}
