.footer {
  p {
    margin-bottom: 0px;
  }
  .fa-heart {
    color: red;
    opacity: 0.6;
  }
  .col-xs-24 {
    margin-bottom: 0px;
    margin-top: -20px;
  }
}
