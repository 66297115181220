// Styling for instructor's view of managing doc production tests
.doc-production-instructor-view {
  .doc-production-results {
    // Variables
    $doc-formatting-symbol-opacity: 0.6;

    .total-score {
      display: flex;
      align-items: center;
      width: 100%;
      flex-direction: column;

      .caption {
        font-size: 0.9em;
      }

      .value {
        color: $brand-primary;
        font-weight: 400;
        padding: 0.3em;
        border-bottom: 1px solid rgba(#000, 0.2);
        box-shadow: 0 12px 10px -3px rgba(#000, 0.1);
        border-radius: 0.3em;
      }
    }

    // Wrapper for a section. contains section Name, ecpected content, typed, score, and errors
    .section-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.6em;
      border-bottom: 2px dotted rgba(#333, 0.1);

      &:hover {
        background-color: rgba(#ccc, 0.1);
      }

      // utility. Delete if not needed
      &.hide-formatting-symbols {
        .section-text-content {
          .bard-return,
          .line-break {
            visibility: hidden;
            display: none;
          }
        }
      }

      .section-name-title {
        color: $brand-primary;
        font-weight: 400;
      }
    }

    .section-item {
      // flex: 1 1 100%;

      padding: 0.3em 0.7em;

      .subheading {
        // color: rgba(#000, 0.4);
        opacity: 0.4;
      }
    }

    .section-text-content {
      // padding: 0.3em 0.7em;
      // flex: 1 1 50%;
      display: flex;
      flex-wrap: wrap;
    }

    .docx-html-block-wrapper {
      flex: 1;
      min-width: 400px;
      display: flex;
      flex-direction: column;
      padding: 0 0.5em;
    }

    .docx-html-block {
      flex: 1;

      &.typed {
      }

      &.expected {
      }

      border-radius: 0.5em;
      white-space: pre-wrap;
      font-family: 'Roboto Mono', monospace;

      // border: 1px solid black;
      padding: 0.3em 0.7em;
      box-shadow: 0px 2px 8px 0px #0003;
      background-color: #fff;
      border: none;
      color: rgba(#000, 0.3);

      // color: rgba(#000, 0.3);
      // List all doc formatting elements here
      .hard-return,
      .line-break,
      .tab,
      .extra-space {
        opacity: $doc-formatting-symbol-opacity;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        // Important so browser properly displays the content as a new-line

        &:hover {
          opacity: 1;

          // color: $brand-primary;
          // background-color: rgba(#000, 0.1);
          color: #fff;
          background-color: rgba(#000, 0.6);
        }

        &:after,
        &:before {
        }
      }

      // Special character formatting that is present in .section-text-content
      .hard-return {
        &:after {
          content: '\A';
          display: block;
          height: 0;
          margin-bottom: 0.5em;
        }
      }

      .line-break {
        &:after {
          content: '\A';
          display: block;
          height: 0;
          margin: 0;
          padding: 0;
        }
      }

      .tab {
        background-color: rgba(#fff, 0);
        position: relative;
        box-sizing: border-box;
        border: 1px solid rgba(#fff, 0.1);

        &:after {
          position: absolute;
          display: flex;
          left: 0;
          top: 0;
          opacity: 1;
          content: '\2192';
          width: 100%;
          height: 100%;
          justify-content: flex-end;
          padding-right: 0.5em;
          box-sizing: border-box;
        }

        &:hover {
          border: 1px solid rgba(#fff, 0.1);
        }
      }

      // Currently disabled because no error segment wrapper is implemented on the backend side
      .error-segment {
        background-color: rgba($brand-primary, 0.05);
        border: 1px solid rgba($brand-primary, 0.2);
        color: rgba(#000, 1);

        // &:hover {
        //   background-color: rgba($brand-primary, 0.6);
        //   color: #fff;
        // }
      }
    }

    .section-errors-container {
      margin: 0.3em 0.7em;

      .errors-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .error-segment {
        margin-bottom: 0.3em;
        color: $brand-primary;
        padding: 0.2em 0.3em;
      }
    }

    .error-segment {
      &.active,
      &:hover {
        color: #fff;
        background-color: rgba($brand-primary, 0.6);
      }
    }
  }
}
